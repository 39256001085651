import { Chip } from "../../product-feed/components/Chip";
import { ColorBoxIcon } from "../../product-feed/components/ColorBoxIcon";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";

export const favoriteTagInfo = {
  name: "Favorite",
};

export function FavoriteTag({ onClick }: { onClick?: (e: any) => void }) {
  return (
    <Chip className="!bg-slate-50 flex items-center gap-1">
      <ColorBoxIcon border={"border-[#FFC42E]"} bg="bg-amber-50" />
      {favoriteTagInfo.name}
      {onClick && (
        <button onClick={onClick}>
          <CloseIcon className="w-3 h-3" />
        </button>
      )}
    </Chip>
  );
}
