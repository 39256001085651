import clsx from "clsx";
import Spinner from "./Spinners";
import { Button } from "../@/components/ui/button";

export default function CustomButton({
  children,
  onClick,
  className,
  disabled,
  type = "button",
  loading,
  variant = "default",
  size = "lg",
  ...props
}: {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  loading?: boolean;
  variant?: "default" | "secondary" | "link" | "ghost";
  size?: "default" | "sm" | "lg" | "icon";
}) {
  return (
    <Button
      className={clsx(
        // "h-10 px-6 py-2 bg-[#030712] rounded-[6px] flex-col justify-center items-center gap-1.5 inline-flex text-[#FAFAFA] text-[0.875rem] font-[Inter] disabled:bg-[#030712a0] transition-all",
        className
      )}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
      variant={variant}
      size={size}
      {...props}
    >
      {loading ? <Spinner /> : children}
    </Button>
  );
}
