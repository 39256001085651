import { Button } from "../../../@/components/ui/button";
import { SearchInput } from "../../../components/Search";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { TagsModal } from "./TagsModal";
import { FilterModal } from "./FilterModal";
import { useState } from "react";
import { StatusFilterSelect } from "./StatusFilterSelect";
import { DateSelect } from "../../product-feed/Feed/components/DateSelect";

{
  /* <StatusFilterSelect
                onChange={(value) => {
                  onChange({
                    ...filters,
                    status: value,
                  });
                }}
                value={filters.status}
              /> */
}

export function FilterView({
  search,
  setSearch,
  onAddSeller,
  filters,
  onClear,
  onChange,
}) {
  const [filtersToShow, setFiltersToShow] = useState<any>([]);
  const [tempFilters, setTempFilters] = useState<any>({});
  return (
    <div className="">
      <div className="flex justify-between gap-2 items-center">
        <div className="flex items-center gap-2">
          <SearchInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="flex-1 md:flex-[unset]"
          />
          <div className="hidden md:block">
            {filtersToShow.includes("timeCreated") && (
              <DateSelect
                name="timeCreated"
                onDateChange={(filter) => {
                  setTempFilters((prev) => ({ ...prev, timeCreated: filter }));
                }}
                value={tempFilters.timeCreated}
                onApply={() => {
                  if (
                    tempFilters?.timeCreated?.startDate &&
                    tempFilters?.timeCreated?.endDate
                  ) {
                    onChange({
                      ...filters,
                      timeCreated: tempFilters.timeCreated,
                    });

                    // onApply({
                    //   name: "timePosted",
                    //   startDate: moment(
                    //     productFilter?.timePosted?.startDate
                    //   ).format(),
                    //   endDate: moment(
                    //     productFilter?.timePosted?.endDate
                    //   ).format(),
                    // });
                  }
                }}
                appliedValue={filters?.timeCreated}
                onClear={() => {
                  onClear();
                  setTempFilters((prev) => ({ ...prev, timeCreated: {} }));
                  // setProductFilter((prev) => ({ ...prev, timePosted: {} }));
                  // onClearFilter("timeCreated");
                }}
                className="!shadow-none"
              />
            )}
          </div>
        </div>
        <div className="flex gap-2 md:gap-3 items-center">
          {/* <TagsModal /> */}
          <FilterModal
            filters={filtersToShow}
            onClear={() => {
              setFiltersToShow([]);
              onChange({});
            }}
            onChange={(filter) => {
              if (filtersToShow.includes(filter.name)) {
                setFiltersToShow(
                  filtersToShow.filter((f) => f !== filter.name)
                );
              } else {
                setFiltersToShow([...filtersToShow, filter.name]);
              }
            }}
          />

          {/* <Button
            onClick={() => onAddSeller()}
            color="black"
            className="flex gap-1.5 items-center !h-8 px-2 md:!px-4"
          >
            <PlusIcon className="w-4 h-4" />
            <span className="hidden md:block">Add a seller</span>
          </Button> */}
        </div>
      </div>
      <div className="block mt-2 md:hidden">
        {filtersToShow.includes("timeCreated") && (
          <DateSelect
            name="timeCreated"
            onDateChange={(filter) => {
              onChange({ ...filters, timeCreated: filter });
            }}
            value={filters.timeCreated}
            onApply={() => {
              if (
                filters?.timeCreated?.startDate &&
                filters?.timeCreated?.endDate
              ) {
                // onApply({
                //   name: "timePosted",
                //   startDate: moment(
                //     productFilter?.timePosted?.startDate
                //   ).format(),
                //   endDate: moment(
                //     productFilter?.timePosted?.endDate
                //   ).format(),
                // });
              }
            }}
            appliedValue={filters?.timeCreated}
            onClear={() => {
              // setProductFilter((prev) => ({ ...prev, timePosted: {} }));
              // onClearFilter("timeCreated");
            }}
            className="!shadow-none"
          />
        )}
      </div>
    </div>
  );
}
