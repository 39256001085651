import { NumberFilter, numberFilterOption } from "../../SellerList/constant";
import { SelectInput } from "./SelectInput";
import { ReactComponent as RoundedPlusIcon } from "../../../../assets/icons/rounded-plus.svg";
import { Popover } from "@radix-ui/themes";
import { Button } from "../../../../@/components/ui/button";
import { DollarInput } from "./DollarInput";

function BetweenInput({ onChange, fromValue, toValue, showDollar }) {
  return (
    <div className="flex gap-2">
      <DollarInput
        className="h-8 w-20"
        onChange={onChange}
        value={fromValue}
        name="fromValue"
        showDollar={showDollar}
      />
      <div className="flex items-center">to</div>
      <DollarInput
        onChange={onChange}
        value={toValue}
        name="toValue"
        showDollar={showDollar}
        className={`h-8 w-20 ${
          toValue && Number(toValue || 0) < Number(fromValue || 0)
            ? "!ring-[#EF4444]/80"
            : ""
        }`}
      />
    </div>
  );
}

export function NumberFilterInput({
  label,
  value,
  onChange,
  onClear,
  suffix,
  name,
  onApply,
  showDollar,
  appliedValue,
}: {
  label: string;
  value: any; // {condition: "", fromValue, toValue}
  onChange: (filter: any) => void;
  suffix?: string;
  name: string;
  onClear: () => void;
  onApply: () => void;
  showDollar?: boolean;
  appliedValue?: any;
}) {
  const handleApply = () => {
    onApply();
  };
  return (
    <>
      <Popover.Root>
        <Popover.Trigger className="w-fit !px-2 !py-2 !rounded-[6px] !h-8">
          <button className="shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)] flex items-center gap-2 border border-dashed border-slate-200 rounded-[6px] bg-white px-[13px] py-[5px]">
            <div className="flex gap-2 items-center">
              <RoundedPlusIcon className="w-4 h-4" />
              <span className="text-xs font-medium leading-[18px] text-slate-900">
                {label}
              </span>
            </div>
            <div className="border-l border-l-slate-200 h-4" />
            <div className="bg-slate-50 leading-[18px] rounded-[6px] py-[2px] px-1.5 text-slate-900 text-xs">
              {
                numberFilterOption.find(
                  (opt) => opt.name === appliedValue?.condition
                )?.text
              }{" "}
              {!appliedValue?.fromValue
                ? `All ${suffix || ""}`
                : `${showDollar ? "$" : ""}${appliedValue?.fromValue} ${
                    appliedValue?.toValue &&
                    appliedValue?.condition === NumberFilter.BETWEEN
                      ? `to ${showDollar ? "$" : ""}${appliedValue?.toValue}`
                      : ""
                  }`}
            </div>
          </button>
        </Popover.Trigger>
        <Popover.Content 
          style={{ zIndex: "101" }} minWidth="250px" className="!p-0">
          <div className="p-1 flex gap-2 items-center border-b border-b-slate-200">
            <SelectInput
              list={numberFilterOption.map((item) => ({
                value: item.name,
                text: item.text,
              }))}
              value={value?.condition || ""}
              onChange={(val) =>
                onChange({
                  ...value,
                  condition: val,
                  fromValue: "",
                  toValue: "",
                })
              }
              label={
                <>
                  {!value?.condition
                    ? "Select option"
                    : numberFilterOption.find(
                        (v) => v.name === value?.condition
                      )?.text}
                </>
              }
            />
            {value?.condition === NumberFilter.BETWEEN ? (
              <BetweenInput
                onChange={(e) =>
                  onChange({ ...value, [e.target.name]: e.target.value })
                }
                fromValue={value?.fromValue || ""}
                toValue={value?.toValue || ""}
                showDollar={showDollar}
              />
            ) : (
              <DollarInput
                className={"w-20"}
                name="fromValue"
                value={value?.fromValue || ""}
                onChange={(e) =>
                  onChange({ ...value, [e.target.name]: e.target.value })
                }
                showDollar={showDollar}
              />
            )}
          </div>
          <div className="flex">
            <Button
              variant="link"
              className="flex-1 !no-underline !outline-none text-slate-700 text-sm text-center w-full !h-10 hover:bg-slate-50"
              onClick={onClear}
            >
              Clear filters
            </Button>
            <Popover.Close>
              <Button
                variant="link"
                className="flex-1 !no-underline !outline-none text-slate-700 text-sm text-center w-full !h-10 hover:bg-slate-50"
                onClick={handleApply}
                disabled={
                  !value?.condition ||
                  !value?.fromValue ||
                  (value?.condition === NumberFilter.BETWEEN &&
                    (!value?.toValue ||
                      Number(value?.toValue || 0) <
                        Number(value?.fromValue || 0)))
                }
              >
                Apply
              </Button>
            </Popover.Close>
          </div>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
