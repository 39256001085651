import { sellerStatus } from "../../../product-feed/SellerList/constant";
import { Select } from "@radix-ui/themes";
import { ColorBoxIcon } from "../../../product-feed/components/ColorBoxIcon";
import { Trigger, Value } from "@radix-ui/react-select";
import { ReactComponent as DownIcon } from "../../../../assets/icons/chevron-down.svg";

export function StatusSelect({ value, onChange }) {
  const statusInfo = sellerStatus.find((status) => status.name === value);
  return (
    <Select.Root value={value} onValueChange={onChange}>
      <Trigger
        style={{ outlineOffset: 2 }}
        className="trigger flex justify-between items-center !border text-slate-400 text-sm leading-[24px] !border-slate-200 !border-solid !shadow-field focus-visible:!outline-[#0F172A] focus-visible:!ring-0 w-full !px-2 !py-2 !rounded-[6px] !h-10"
      >
        <Value placeholder="Search" className="justify-start">
          {value && statusInfo ? (
            <div className="hover:!bg-[#F1F5F9] focus:!bg-[#F1F5F9] focus:text-slate700 hover:text-slate700">
              <div
                className={`py-[2px] px-1.5 text-xs font-medium text-slate-900 border rounded-[6px] flex items-center gap-1`}
              >
                <ColorBoxIcon bg={statusInfo.bg} border={statusInfo.border} />
                {statusInfo.title}
              </div>
            </div>
          ) : (
            <span className="text-slate-400 text-sm leading-[24px]">
              Search
            </span>
          )}
        </Value>
        <DownIcon />
      </Trigger>
      <Select.Content position="popper">
        {sellerStatus.map((statusInfo) => (
          <Select.Item
            value={statusInfo.name}
            key={statusInfo.name}
            className="hover:!bg-[#F1F5F9] focus:!bg-[#F1F5F9] focus:text-slate700 hover:text-slate700"
          >
            <div
              className={`py-[2px] px-1.5 text-xs font-medium text-slate-900 border rounded-[6px] flex items-center gap-1`}
            >
              <ColorBoxIcon bg={statusInfo.bg} border={statusInfo.border} />
              {statusInfo.title}
            </div>
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
}
