import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./module/login/Login";
import { Main } from "./main";
import Dashboard from "./module/dashboard/Dashboard";
import Users from "./module/users/Users";
import SuspenseWrapper from "./components/SuspenseWrapper";
import Sellers from "./module/sellers/Sellers";
import { SellerProductProvider } from "./module/sellers/context/seller-product-context";
import { SellerProductProvider as UserSellerProductProvider } from "./module/users/components/context/seller-product-context";

// import Dashboard from "./module/dashboard";
// import SuspenseWrapper from "components/SuspenseWrapper";
// import { Main } from "module/main";

const RoutesList = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="login" element={<Login />} />
      </Route>
      <Route element={<Main />}>
        <Route
          path="/dashboard"
          element={
            <SuspenseWrapper>
              <Dashboard />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/users"
          element={
            <SuspenseWrapper>
              <UserSellerProductProvider>
                <Users />
              </UserSellerProductProvider>
            </SuspenseWrapper>
          }
        />
        <Route
          path="/sellers"
          element={
            <SuspenseWrapper>
              <SellerProductProvider>
                <Sellers />
              </SellerProductProvider>
            </SuspenseWrapper>
          }
        />
        <Route
          index
          element={
            // if loggged in redirect to dashboard
            <Navigate to="/login" />
          }
        />
      </Route>
    </Routes>
  );
};
export default RoutesList;
