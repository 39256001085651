import { FilterViewUI } from "../../../product-feed/Feed/components/FilterView";
import { useSellerProduct } from "../../context/seller-product-context";

export function SellerProductFilterView() {
  const {
    productFilter,
    setProductFilter,
    selectedSeller,
    onClearFilter,
    onClearAllFilters,
    onApply,
    onSearch,
    searchState,
    total,
    appliedFilter,
    setAppliedFilter,
  } = useSellerProduct() as any;

  return (
    <FilterViewUI
      onApply={onApply}
      onClearAllFilters={onClearAllFilters}
      onClearFilter={onClearFilter}
      onSearch={onSearch}
      productFilter={productFilter}
      searchState={searchState}
      selectedSeller={selectedSeller}
      total={total}
      setProductFilter={setProductFilter}
      showTotal={false}
      appliedFilter={appliedFilter}
      setAppliedFilter={setAppliedFilter}
    />
  );
}
