import React, { useEffect, useState, useCallback } from "react";
import { Tag } from "./types";
import { api } from "../../../helpers/api";
import { getUser } from "../../../helpers/utils";

export const TagsContext = React.createContext({});

export function TagsProvider({ children }) {
  const [tagList, setTagList] = React.useState<string[] | null>(null);
  const [loading, setLoading] = useState(false);
  const user = getUser();

  const fetchTags = useCallback(async (userId?: string) => {
    if (!userId) return;
    try {
      setLoading(true);
      const res = await api.getTags(userId);
      setLoading(false);
      setTagList(res.tags || []);
    } catch (e) {}
  }, []);

  const refetch = async () => {
    if (!user.id) return;
    await fetchTags(user?.id);
  };

  useEffect(() => {
    if (user.id) {
      fetchTags(user.id);
    }
  }, [user.id, fetchTags]);

  const values = {
    tagList,
    setTagList,
    loading,
    setLoading,
    refetch,
  };

  return <TagsContext.Provider value={values}>{children}</TagsContext.Provider>;
}

export function useTags() {
  const context = React.useContext(TagsContext);
  if (context === undefined) {
    throw new Error("useTags must be used within a TagsProvider");
  }
  return context;
}
