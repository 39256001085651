import { SearchInput } from "../../../../components/Search";
import { useState } from "react";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/check2.svg";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter.svg";
import { Popover } from "@radix-ui/themes";
import { Button } from "../../../../@/components/ui/button";
import { PlanTypeSelect } from "./PlanTypeSelect";
import { JoinedOnSelect } from "../../../product-feed/Feed/components/JoinedOnSelect";
import { PlusIcon } from "lucide-react";
import Modal from "../../../../components/Modal";
import { Form, Formik } from "formik";
import FormField from "../../../../components/FormField";
import * as Yup from "yup";
import { api } from "../../../../helpers/api";
import { useToast } from "../../../../@/components/ui/use-toast";
import Spinner from "../../../../components/Spinners";

const filterList = [
  {
    name: "joinedOn",
    title: "Joined on",
  },
  {
    name: "planType",
    title: "Plan type",
  },
];

export function UserFilterView({
  search,
  setSearch,
  filters,
  onClear,
  onChange,
  showFilter = true,
}: {
  search: string;
  setSearch: any;
  filters: any;
  onClear: any;
  onChange: any;
  showFilter?: boolean;
}) {
  const [filtersToShow, setFiltersToShow] = useState<any>([]);
  const [isInviteUserModal, setIsInviteUserModal] = useState(false);
  const handleInviteUserModal = () => setIsInviteUserModal(!isInviteUserModal);

  return (
    <div className="flex justify-between gap-2 items-center">
      <div className="flex items-center gap-2">
        <SearchInput
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="flex-1 md:flex-[unset]"
        />
        {showFilter && (
          <div className="flex flex-row gap-2">
            {filtersToShow.includes("planType") && (
              <PlanTypeSelect
                onChange={(value) => {
                  onChange({
                    ...filters,
                    tiers: value,
                  });
                }}
                value={filters.tiers}
              />
            )}
            {filtersToShow.includes("joinedOn") && (
              <JoinedOnSelect
                name="TimePosted"
                onDateChange={(filter) => {
                  onChange({
                    ...filters,
                    ...filter,
                  });
                }}
                value={
                  filters.createdAt ? new Date(filters.createdAt) : new Date()
                }
                filters={filters}
                setFilters={onChange}
                appliedValue={filters.createdAt || ""}
                onClear={() => {
                  onChange({
                    ...filters,
                    createdAt: "",
                  });
                }}
                className="bg-white"
              />
            )}
          </div>
        )}
      </div>
      {showFilter && (
        <div className="flex gap-2 md:gap-3 items-center">
          {/* <TagsModal /> */}
          <FilterModal
            filters={filtersToShow}
            onClear={() => {
              setFiltersToShow([]);
              onClear();
            }}
            onChange={(filter) => {
              if (filtersToShow.includes(filter.name)) {
                setFiltersToShow(
                  filtersToShow.filter((f) => f !== filter.name)
                );
              } else {
                setFiltersToShow([...filtersToShow, filter.name]);
              }
            }}
          />
          <Button
            className="px-3 py-1 flex flex-row gap-1.5 h-8"
            onClick={handleInviteUserModal}
          >
            <PlusIcon width={16} height={16} />
            Add User
          </Button>
        </div>
      )}
      <AddUser isOpen={isInviteUserModal} handleModal={handleInviteUserModal} />
    </div>
  );
}

function FilterModal({ onClear, filters, onChange }) {
  return (
    <div>
      <Popover.Root>
        <Popover.Trigger>
          <Button
            variant="outline"
            className="rounded-[6px] !px-3 !py-1 flex gap-1.5 items-center bg-white border border-slate-200 h-8 flex-row"
          >
            <FilterIcon />
            <span className="h-6 content-center place-content-center">
              Filters
            </span>
          </Button>
        </Popover.Trigger>
        <Popover.Content minWidth="200px" maxWidth="200px" className="!p-0">
          <div className="text-slate-700 flex flex-col justify-center px-3 h-10 text-sm font-semibold leading-[20px]">
            User Filters
          </div>
          <div className="py-1 px-1 border-y border-y-slate-200">
            {filterList.map((filter) => (
              <Button
                variant="link"
                className="flex px-2 py-1 items-center gap-2 w-full !no-underline justify-start hover:bg-slate-50"
                key={filter.name}
                onClick={() => {
                  console.log(filter);
                  onChange(filter);
                }}
              >
                <div className="w-4 h-4">
                  {filters.includes(filter.name) ? <CheckIcon /> : null}
                </div>
                <div className="text-sm text-slate-700 leading-[20px] rounded-[6px] px-1.5 py-[2px] w-fit">
                  {filter.title}
                </div>
              </Button>
            ))}
          </div>
          <Button
            variant="link"
            className="!no-underline text-slate-700 text-sm text-center w-full !h-10 hover:bg-slate-50"
            onClick={onClear}
          >
            Clear filters
          </Button>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
}
function AddUser({
  isOpen,
  handleModal,
}: {
  isOpen: boolean;
  handleModal: () => void;
}) {
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });
  const { toast } = useToast();

  return (
    <Modal open={isOpen} handleModal={handleModal} title="Invite User">
      <Formik
        initialValues={{ fullName: "", email: "" }}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={(values, actions) => {
          // Handle form submission
          api
            .inviteUser(values)
            .then(() => {
              handleModal();
              actions.setSubmitting(false);
              toast({
                description: "User invited successfully",
              });
            })
            .catch((err) => {
              actions.setSubmitting(false);
              toast({
                description: "Failed to invite user",
                variant: "destructive",
              });
            });
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form className="grid gap-[1.75rem] mt-4">
            <div className="grid gap-4">
              <FormField
                label="Full Name"
                name="fullName"
                type="text"
                placeholder="Enter your full name"
              />
              <FormField
                label="Email"
                name="email"
                type="email"
                placeholder="Enter your email"
              />
            </div>
            <div className="grid gap-3">
              <Button
                className="w-full flex items-center gap-1"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                Invite {isSubmitting && <Spinner />}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
