import { useEffect, useRef, useState } from "react";
import { useDebounce } from "../../../helpers/useDebounce";
import { api } from "../../../helpers/api";
export const LIMIT = 2;

function useSellerProducts() {
  const [sellerId, setSellerId] = useState<any>(null);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: LIMIT,
  });
  const [state, setState] = useState<any>({
    keyword: "",
  });
  const [filters, setFilters] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const debouncedFilters = useDebounce(state, 500);
  const signalRef = useRef<any>();
  const fetchingParams = useRef<any>();

  const fetchProducts = async (
    seller = sellerId,
    params = state,
    filterList = filters,
    pageInfo = pagination,
    refetchId?: string,
    isDeleted?: boolean
  ) => {
    if (!seller) return;
    try {
      setIsLoading(true);
      const { products: data = [], totalProducts } = await api.getProducts(
        seller,
        {
          ...params,
          ...filterList,
          ...pageInfo,
        }
      );
      setIsLoading(false);
      // filter deleted question
      if (isDeleted && refetchId) {
        setData((prev) =>
          prev.filter((item) => item.question_id !== refetchId)
        );
      } else if (refetchId) {
        setData((prev) => [
          ...prev.filter((item) => item.question_id !== refetchId),
          ...data,
        ]);
      } else {
        if (pageInfo.page === 0) {
          setData([...data]);
        } else {
          setData((prev) => [...prev, ...data]);
        }
      }
      setTotal(totalProducts);
    } catch (e) {}
  };

  const refetch = (refetchId?: string, isDeleted?: boolean) => {
    fetchProducts(
      sellerId,
      debouncedFilters,
      filters,
      pagination,
      refetchId,
      isDeleted
    );
  };

  const loadMore = () => {
    // update page only we have data
    const page = pagination.page;
    const totalPage = Math.ceil(total / pagination.limit);
    if (page < totalPage - 1) {
      setIsLoading(true);
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      fetchProducts(sellerId, debouncedFilters, filters, {
        ...pagination,
        page: pagination.page + 1,
      });
    }
  };

  const onPaginationChange = (pagination) => {
    setPagination(pagination);
    fetchProducts(sellerId, debouncedFilters, filters, pagination);
  };

  const handleFilters = (updates: any, clearAllFilter = false) => {
    // update filters & reset to first page
    setIsLoading(true);
    setData([]);
    if (signalRef.current) {
      signalRef.current.abort();
      signalRef.current = null;
    }
    setPagination((prev) => ({ ...prev, page: 0 }));
    if (clearAllFilter) {
      setFilters({});
    } else {
      setFilters((prev) => ({ ...prev, ...updates }));
    }
    fetchProducts(
      sellerId,
      state,
      clearAllFilter ? {} : { ...filters, ...updates },
      { page: 0, limit: pagination.limit }
    );
  };

  useEffect(() => {
    if (signalRef.current) {
      signalRef.current.abort();
      signalRef.current = null;
    }
    fetchProducts(sellerId, debouncedFilters, undefined, {
      page: 0,
      limit: LIMIT,
    });
  }, [debouncedFilters, sellerId]);

  const products: any[] = data || [];

  return {
    products,
    totalPages: Math.ceil(total / pagination.limit),
    total: total,
    pagination,
    isLoading: isLoading,
    isFetching,
    filters: state,
    updateFilters: (updates, clearAllFilter?: boolean) =>
      handleFilters(updates, clearAllFilter),
    onSearch: (keyword: string) => {
      setState({ keyword });
      setPagination({ page: 0, limit: pagination.limit });
    },
    state,
    refetch,
    loadMore,
    onPaginationChange,
    setSellerId,
    reset: () => {
      setData([]);
      setPagination({ page: 0, limit: pagination.limit });
      setState({
        keyword: "",
      });
      setFilters({});
    },
  };
}

export default useSellerProducts;
