import { Popover, Select } from "@radix-ui/themes";
import { Chip } from "../../../product-feed/components/Chip";
import { Button } from "../../../../@/components/ui/button";
import { ReactComponent as DownIcon } from "../../../../assets/icons/chevron-down.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/check2.svg";

import { SearchInput } from "../../../../components/Search";
import { useState } from "react";
import { useTags } from "../context/tags-context";
import { FavoriteTag, favoriteTagInfo } from "./FavoriteTag";

const filterTags = (tags, search) => {
  if (!search) return tags;
  return tags.filter((tag) => tag.toLowerCase().includes(search.toLowerCase()));
};

const showFavorite = (name, search) => {
  if (!search) return true;
  if (name.includes(search)) {
    return true;
  }
  return false;
};

export const tagsToSlice = (tags) => {
  // Maximum 40 characters combined all tags
  // Show as much as possible tags until 40 characters
  let numberOfTags = 0;
  let totalLength = 0;
  for (let i = 0; i < tags.length; i++) {
    totalLength += tags[i].length;
    if (totalLength > 40) {
      break;
    }
    numberOfTags++;
  }
  return numberOfTags;
};

export function TagsSelect({
  value = [],
  onChange,
  error,
  tagList,
}: {
  value?: string[];
  onChange: (value: string[]) => void;
  error?: string;
  tagList?: string[];
}) {
  const [search, setSearch] = useState("");
  const handleChange = (tag) => {
    if (value.includes(tag)) {
      onChange(value.filter((t) => t !== tag));
    } else {
      onChange([...value, tag]);
    }
  };

  const numberOfTags = tagsToSlice(value);
  const remainingTags = value.length - numberOfTags;
  return (
    <>
      <Popover.Root>
        <Popover.Trigger className="w-full !px-2 !py-2 !rounded-[6px] !h-10">
          <Button
            variant={"link"}
            className="focus-visible:!outline-2 border border-slate-200 shadow-field focus-visible:!outline-[#0F172A] focus-visible:!ring-0 !no-underline justify-between gap-1.5"
          >
            <div className="flex justify-start gap-[5px] flex-wrap">
              {value?.length > 0 ? (
                <>
                  {value.slice(0, numberOfTags)?.map((tag, i) =>
                    tag.toLowerCase() === favoriteTagInfo.name.toLowerCase() ? (
                      <FavoriteTag
                        key={i}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleChange(favoriteTagInfo.name);
                        }}
                      />
                    ) : (
                      <Chip
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleChange(tag);
                        }}
                        key={tag}
                      >
                        {tag}
                      </Chip>
                    )
                  )}
                  {remainingTags > 0 && <Chip>+{remainingTags}</Chip>}
                </>
              ) : (
                <span className="text-slate-400 text-sm leading-[24px]">
                  Tags
                </span>
              )}
            </div>
            <DownIcon />
          </Button>
        </Popover.Trigger>
        <Popover.Content
          style={{ zIndex: "102" }}
          minWidth="250px"
          maxWidth="250px"
          className="!p-0"
        >
          <SearchInput
            className="!border-none !shadow-none !outline-none w-full !px-1 !pr-3 !py-2 !h-10 !m-0"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="border-y border-y-slate-200 p-1 flex flex-col justify-start max-h-[16vh] overflow-auto">
            {showFavorite(favoriteTagInfo.name, search) && (
              <Button
                variant="link"
                className="flex px-2 py-[5px] items-center gap-2 w-full !no-underline justify-start hover:bg-slate-50 focus:bg-slate-50"
                key={favoriteTagInfo.name}
                onClick={() => handleChange(favoriteTagInfo.name)}
              >
                <div className="w-4 h-4">
                  {value.includes(favoriteTagInfo.name) ? <CheckIcon /> : null}
                </div>
                <FavoriteTag />
              </Button>
            )}
            {filterTags(tagList, search)?.map((tag, i) => (
              <Button
                variant="link"
                className="flex px-2 py-[5px] items-center gap-2 w-full !no-underline justify-start hover:bg-slate-50 focus:bg-slate-50"
                key={tag}
                onClick={() => handleChange(tag)}
              >
                <div className="w-4 h-4">
                  {value.includes(tag) ? <CheckIcon /> : null}
                </div>
                <div
                  key={tag}
                  className="font-medium text-xs text-slate-900 border border-slate-200 bg-slate-50 rounded-[6px] px-1.5 py-[2px] w-fit"
                >
                  {tag}
                </div>
              </Button>
            ))}
          </div>
          <Button
            variant="link"
            className="!no-underline text-slate-700 text-sm text-center w-full !h-10 hover:bg-slate-50"
            onClick={() => onChange([])}
          >
            Clear Tags
          </Button>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
