import clsx from "clsx";
import ShowIcon from "../assets/icons/show.svg";
import HideIcon from "../assets/icons/hide.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Input } from "../@/components/ui/input";
export default function CustomInput({
  label,
  type,
  name,
  id,
  value,
  placeholder,
  onChange,
  className,
  error,
  forgotLink,
  disabled,
  ...props
}: {
  label: string;
  type: string;
  name: string;
  id: string;
  value: string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  error?: any;
  forgotLink?: boolean;
  disabled?: boolean;
}) {
  const [showPassword, setShowPassword] = useState(false);
  if (type === "password") {
    return (
      <div className={clsx("flex flex-col gap-[0.375rem]", className)}>
        <label
          htmlFor={id}
          className="text-[#030712] text-[0.875rem] font-[Inter] justify-between flex w-full font-[500]"
        >
          {label}
          {forgotLink && (
            <Link to="/forgot-password" className="underline font-medium">
              Forgot your password?
            </Link>
          )}
        </label>
        <div className="relative">
          <Input
            type={showPassword ? "text" : "password"}
            name={name}
            id={id}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            maxLength={40}
            disabled={disabled}
            className="pr-10"
            {...props}
          />
          <button
            type="button"
            className="absolute right-3 top-1/2 transform -translate-y-1/2"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            <img src={showPassword ? HideIcon : ShowIcon} alt="show" />
          </button>
        </div>
        {error && <p className="text-[#EF4444] text-[0.75rem]">{error}</p>}
      </div>
    );
  }

  return (
    <div className={clsx("flex flex-col gap-[0.375rem]", className)}>
      <label
        htmlFor={id}
        className="text-[#030712] text-[0.875rem] font-[Inter] font-[500]"
      >
        {label}
      </label>
      <Input
        type={type}
        name={name}
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={40}
        disabled={disabled}
        {...props}
      />

      {error && <p className="text-[#EF4444] text-[0.75rem]">{error}</p>}
    </div>
  );
}
