import { useEffect, useMemo, useState } from "react";
import { api } from "../../../../helpers/api";

export function useSeller(userId: string) {
  const [sellerList, setSellerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({
    key: "",
    order: "",
  });
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState<any>({});

  const fetchSellers = async (userId?: string) => {
    if (!userId) return;
    try {
      setLoading(true);
      const res = await api.getSellers(userId);
      setSellerList(res || []);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSellers(userId);
  }, [userId]);

  const filteredSellers = useMemo(() => {
    if (!sellerList) return [];
    if (!search && !sorting.key && !filters.status) return sellerList;
    let list = [...sellerList];
    if (search) {
      list = list.filter(
        (seller: any) =>
          seller &&
          ((seller?.sellerName || seller?.nickName)
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
            seller?.sellerId?.toLowerCase().includes(search.toLowerCase()))
      );
    }
    if (sorting.key) {
      list = list.sort((a: any, b: any) => {
        const aVal =
          sorting.key === "sellerName"
            ? a[sorting.key] || a["nickName"]
            : a[sorting.key];
        const bVal =
          sorting.key === "sellerName"
            ? b[sorting.key] || b["nickName"]
            : b[sorting.key];

        if (aVal < bVal) {
          return sorting.order === "asc" ? -1 : 1;
        }
        if (aVal > bVal) {
          return sorting.order === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    // check for filters
    if (filters.status) {
      list = list.filter((seller: any) => seller.status === filters.status);
    }
    return list;
  }, [sellerList, filters.status, search, sorting]);

  const totalActiveUsers = useMemo(() => {
    return sellerList?.filter((seller: any) => seller.status === "A").length;
  }, [sellerList]);

  return {
    sellerList: filteredSellers,
    totalActiveUsers,
    loading,
    sorting,
    setSorting,
    search,
    setSearch,
    fetchSellers,
    filters,
    setFilters,
  };
}
