import "./assets/fonts/stylesheet.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import RoutesList from "./routes";
import "./globals.css";

function App() {
  const queryClient = new QueryClient();
  return (
    <div className="App font-inter">
      <QueryClientProvider client={queryClient}>
        <RoutesList />
      </QueryClientProvider>
    </div>
  );
}

export default App;
