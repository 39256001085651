import { Popover } from "@radix-ui/themes";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { Button } from "../../../@/components/ui/button";

export function SellerListRowAction({
  onView,
  onPause,
  onRemove,
  onActive,
  status,
}: {
  onView: () => void;
  onPause?: () => void;
  onRemove?: () => void;
  onActive?: () => void;
  status: string;
}) {
  return (
    <Popover.Root>
      <Popover.Trigger>
        <Button
          variant="outline"
          className="border flex justify-center items-center border-slate200 !px-0 !py-0 !w-7 !h-7 rounded-[6px]"
          onClick={(e) => e.stopPropagation()}
        >
          <DotsVerticalIcon />
        </Button>
      </Popover.Trigger>
      <Popover.Content
        className="!p-[5px] flex flex-col"
        onClick={(e) => e.stopPropagation()}
      >
        <Button
          variant={"outline"}
          className="!shadow-none !border-none w-[122px] justify-start !px-2 !py-1.5 !h-8"
          onClick={onView}
        >
          View details
        </Button>
        {onPause && (
          <Button
            variant={"outline"}
            className="!shadow-none !border-none w-[122px] justify-start !px-2 !py-1.5 !h-8"
            onClick={status === "A" ? onPause : onActive}
          >
            {status === "A" ? "Pause" : "Activate"} Seller
          </Button>
        )}
        {onRemove && (
          <Button
            variant={"outline"}
            className="!shadow-none !text-[#DC3F0F] !border-none w-[122px] justify-start !px-2 !py-1.5 !h-8"
            onClick={onRemove}
          >
            Remove
          </Button>
        )}
      </Popover.Content>
    </Popover.Root>
  );
}
