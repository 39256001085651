import { useEffect, useMemo, useRef, useState } from "react";
import { api } from "../../../helpers/api";
import { useDebounce } from "../../../helpers/useDebounce";
export const LIMIT = 10;

export function useSeller() {
  const [sellerList, setSellerList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
  const signalRef = useRef<any>();

  const [sorting, setSorting] = useState({
    key: "",
    order: "",
  });
  const [pagination, setPagination] = useState({
    page: 0,
    limit: LIMIT,
  });
  const [state, setState] = useState<any>({
    keyword: "",
  });
  const [filters, setFilters] = useState<any>({});
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const debouncedFilters = useDebounce(state, 500);

  const fetchSellers = async (
    params = state,
    filterList = filters,
    pageInfo = pagination,
    sort = sorting,
    refetchId?: string,
    isDeleted?: boolean
  ) => {
    try {
      setIsLoading(true);
      const res = await api.getAllSellers({
        ...params,
        ...filterList,
        ...pageInfo,
        sortBy: sort.key,
        sortOrder: sort.order,
      });
      console.log("seller", res);
      setIsLoading(false);
      // filter deleted question
      if (isDeleted && refetchId) {
        setSellerList((prev) => prev.filter((item) => item.id !== refetchId));
      } else if (refetchId) {
        setSellerList((prev) => [
          ...prev.filter((item) => item.id !== refetchId),
          ...res,
        ]);
      } else {
        if (pageInfo.page === 0) {
          setSellerList([...res]);
        } else {
          setSellerList((prev) => [...prev, ...res]);
        }
      }
      if (res.length === 0 || res.length < pagination.limit) {
        setHasMore(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const refetch = (refetchId?: string, isDeleted?: boolean) => {
    fetchSellers(
      debouncedFilters,
      filters,
      pagination,
      sorting,
      refetchId,
      isDeleted
    );
  };

  const loadMore = () => {
    // update page only we have data
    const page = pagination.page;
    const totalPage = Math.ceil(total / pagination.limit);
    if (hasMore) {
      setIsLoading(true);
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      fetchSellers(debouncedFilters, filters, {
        ...pagination,
        page: pagination.page + 1,
      });
    }
  };

  const onPaginationChange = (pagination) => {
    setPagination(pagination);
    fetchSellers(debouncedFilters, filters, pagination);
  };

  const handleFilters = (updates: any, clearAllFilter = false) => {
    // update filters & reset to first page
    setIsLoading(true);
    setSellerList([]);
    setHasMore(true);
    if (signalRef.current) {
      signalRef.current.abort();
      signalRef.current = null;
    }
    setPagination((prev) => ({ ...prev, page: 0 }));
    if (clearAllFilter) {
      setFilters({});
    } else {
      setFilters((prev) => ({ ...updates }));
    }
    fetchSellers(state, clearAllFilter ? {} : { ...updates }, {
      page: 0,
      limit: pagination.limit,
    });
  };

  useEffect(() => {
    if (signalRef.current) {
      signalRef.current.abort();
      signalRef.current = null;
    }
    setSellerList([]);
    setPagination({ page: 0, limit: pagination.limit });
    setHasMore(true);
    fetchSellers(debouncedFilters, filters, {
      page: 0,
      limit: pagination.limit,
    });
  }, [debouncedFilters]);

  return {
    sellerList,
    isLoading,
    sorting,
    setSorting: ({ key, order }: { key: string; order: string }) => {
      setHasMore(true);
      setSellerList([]);
      fetchSellers(
        state,
        filters,
        { page: 0, limit: pagination.limit },
        { key, order }
      );
      setPagination({ page: 0, limit: pagination.limit });
      setSorting({ key, order });
    },
    state,
    onSearch: (keyword: string) => setState({ keyword }),
    fetchSellers,
    filters,
    setFilters,
    loadMore,
    hasMore,
    totalPages: Math.ceil(total / pagination.limit),
    total: total,
    pagination,
    updateFilters: (updates, clearAllFilter?: boolean) =>
      handleFilters(updates, clearAllFilter),
    refetch,
    onPaginationChange,
    reset: () => {
      setSellerList([]);
      setHasMore(true);
      setPagination({ page: 0, limit: pagination.limit });
      setState({
        keyword: "",
      });
      setFilters({});
    },
  };
}
