import { Button } from "../../../../@/components/ui/button";
import { ReactComponent as SellersIcon } from "../../../../assets/icons/sellers.svg";
import { useNavigate } from "react-router-dom";

export function EmptyFeed({ showAddSeller = true }) {
  const navigate = useNavigate();

  const handleAddSeller = () => {
    navigate("/sellers");
  };
  return (
    <div className="flex-1 flex justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-3">
        <h2 className="text-center font-grotesk text-[28px] font-medium leading-[33.6px]">
          No Results Yet
        </h2>
        <div className="text-center text-slate-900 tracking-[-0.3px] leading-[20px] text-sm">
          We will notify you as soon as new products are added.
        </div>
        {showAddSeller && (
          <Button
            onClick={handleAddSeller}
            className="w-fit flex gap-1.5 items-center !h-8"
          >
            <SellersIcon className="w-4 h-4 [&_path]:stroke-white" />
            Add a seller
          </Button>
        )}
      </div>
    </div>
  );
}
