import moment from "moment";
import apiClient from "./client";
import { convertDollarToCent } from "./utils";

export const api = {
  login: (data: any) => {
    return apiClient.post("/auth/login", data);
  },
  register: (data: any) => {
    return apiClient.post("/auth/register", data);
  },
  forgotPassword: (data: any) => {
    return apiClient.post("/auth/forgot-password", data);
  },
  resetPassword: (data: any) => {
    return apiClient.post("/auth/reset-password", data);
  },
  getUserProfile: (id: string) => {
    return apiClient.get(`/users/${id}`).then((res) => res.data);
  },
  patchPassword: (id: string, data: any) => {
    return apiClient.patch(`/users/${id}/password`, data);
  },
  putUserProfile: (id: string, data: any) => {
    return apiClient.put(`/users/${id}`, data);
  },
  getUserSubscription: (id: string) => {
    return apiClient.get(`/users/${id}/subscriptions`).then((res) => res.data);
  },
  getStripe: (id: string, tier: "ST" | "PR" | "MX" | "UL") => {
    return apiClient
      .get(`/users/${id}/stripe-connect-url`, {
        params: {
          // redirectUrl: "https://dev.app.stealthseller.co/dashboard",
          // redirectUrl: "http://localhost:3002/dashboard",
          // dynamicRedirectUrl
          redirectUrl: window.location.origin + "/dashboard",
          planType: "M",
          tier: tier,
        },
      })
      .then((res) => res.data);
  },
  patchStripe: (
    id: string,
    tier: "ST" | "PR" | "MX" | "UL",
    subscriptionId: string
  ) => {
    return apiClient.patch(`/users/${id}/subscriptions/${subscriptionId}`, {
      tier: tier,
      planType: "M",
    });
  },
  customerStripePortal: (id: string) => {
    return apiClient
      .get(`/users/${id}/stripe-customer-portal-url`, {
        params: {
          // redirectUrl: "https://dev.app.stealthseller.co/dashboard",
          // redirectUrl: "http://localhost:3002/dashboard",
          // dynamicRedirectUrl
          redirectUrl: window.location.origin + "/dashboard",
        },
      })
      .then((res) => res.data);
  },
  cancelSubscription: (id: string, subscriptionId: string) => {
    return apiClient
      .delete(`/users/${id}/subscriptions/${subscriptionId}`)
      .then((res) => res.data);
  },
  getUser: (id: string) => {
    return apiClient.get(`/users/${id}`).then((res) => res.data);
  },
  saveTags: (userId: string, tags: string[]) => {
    return apiClient.post(`/users/${userId}/tags`, {
      tags,
    });
  },
  getTags: (userId: string) => {
    return apiClient.get(`/users/${userId}/tags`).then((res) => res.data);
  },
  deleteTag: (tagId: string) => {
    return apiClient.delete(`/tags/${tagId}`);
  },
  initiateEmailUpdate: (userId: string, email: string) => {
    return apiClient.post(`/users/${userId}/email/update/initiate`, {
      email,
    });
  },
  updateEmail: (userId: string, otp: string) => {
    return apiClient.post(`/users/${userId}/email/update`, {
      otp,
    });
  },
  addSeller: (userId: string, data: any) => {
    ///users/{id}/sellers
    const url = `/users/${userId}/sellers`;
    return apiClient.post(url, data);
  },
  getSellers: (userId: string) => {
    return apiClient.get(`/users/${userId}/sellers`).then((res) => res.data);
  },
  getAllSellers: ({ page, limit, keyword, timeCreated, sortBy, sortOrder }) => {
    let url = `/sellers?page=${page}&limit=${limit}`;
    if (keyword) {
      url += `&searchKeyword=${keyword}`;
    }

    if (timeCreated?.startDate) {
      url += `&startDate=${moment(timeCreated.startDate).format("YYYY-MM-DD")}`;
    }
    if (timeCreated?.endDate) {
      url += `&endDate=${moment(timeCreated.endDate).format("YYYY-MM-DD")}`;
    }
    if (sortBy) {
      url += `&sortBy=${sortBy}`;
    }
    if (sortOrder) {
      url += `&sortOrder=${sortOrder}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getSeller: (id: string) => {
    const url = `/sellers/${id}`;
    return apiClient.get(url).then((res) => res.data);
  },
  fetchSeller: (userId: string, id: string) => {
    const url = `/users/${userId}/sellers/${id}`;
    return apiClient.get(url).then((res) => res.data);
  },
  updateSeller: (userId: string, data: any) => {
    const url = `/users/${userId}/sellers/${data.id}`;
    return apiClient.patch(url, data);
  },
  removeSeller: (userId: string, id: string) => {
    const url = `/users/${userId}/sellers/${id}`;
    return apiClient.delete(url);
  },
  deleteUser: (userId: string) => {
    return apiClient.delete(`/users/${userId}`);
  },
  getCategories: (userId: string) => {
    return apiClient.get(`/users/${userId}/categories`).then((res) => res.data);
  },
  getProducts: (sellerId: string, data: any) => {
    let queryString = `?page=${data.page}&limit=${data.limit}`;
    if (data.keyword) {
      queryString += `&searchKeyword=${data.keyword}`;
    }
    if (data.categories && data.categories.length > 0) {
      queryString += `&categories=${encodeURIComponent(
        data.categories.join(",")
      )}`;
    }
    if (data.minBuyBoxPrice !== undefined) {
      queryString += `&minBuyBoxPrice=${convertDollarToCent(
        Number(data.minBuyBoxPrice || 0)
      )}`;
    }
    if (data.maxBuyBoxPrice !== undefined) {
      queryString += `&maxBuyBoxPrice=${convertDollarToCent(
        Number(data.maxBuyBoxPrice || 0)
      )}`;
    }
    if (data.minOffersCount !== undefined) {
      queryString += `&minOffersCount=${data.minOffersCount}`;
    }
    if (data.maxOffersCount !== undefined) {
      queryString += `&maxOffersCount=${data.maxOffersCount}`;
    }
    if (data.minSalesRank !== undefined) {
      queryString += `&minSalesRank=${data.minSalesRank}`;
    }
    if (data.maxSalesRank !== undefined) {
      queryString += `&maxSalesRank=${data.maxSalesRank}`;
    }
    if (data.minMonthlySales !== undefined) {
      queryString += `&minMonthlySales=${data.minMonthlySales}`;
    }
    if (data.maxMonthlySales !== undefined) {
      queryString += `&maxMonthlySales=${data.maxMonthlySales}`;
    }
    if (data.minTimePosted !== undefined) {
      queryString += `&minTimePosted=${data.minTimePosted}`;
    }
    if (data.maxTimePosted !== undefined) {
      queryString += `&maxTimePosted=${data.maxTimePosted}`;
    }
    return apiClient
      .get(`/sellers/${sellerId}/products${queryString}`)
      .then((res) => res.data);
  },
  getGraph: (sellerId: string, productId: string) => {
    // /sellers/{id}/products/{productId}/graph
    return apiClient.get(`/sellers/${sellerId}/products/${productId}/graph`, {
      responseType: "blob",
    });
  },
  getSavedProducts: (userId: string) => {
    ///users/{id}/products
    return apiClient.get(`/users/${userId}/products`).then((res) => res.data);
  },
  saveProduct: (userId: string, productId: string) => {
    // users/{id}/products/{productId}
    return apiClient.post(`/users/${userId}/products/${productId}`);
  },
  removeSavedProduct: (userId: string, productId: string) => {
    // users/{id}/products/{productId}
    return apiClient.delete(`/users/${userId}/products/${productId}`);
  },
  markAsRead: (sellerId: string) => {
    return apiClient.post(`/sellers/${sellerId}/read`);
  },
  fetchDashboard: (userId: string) => {
    return apiClient.get(`/users/${userId}/dashboard`).then((res) => res.data);
  },
  getUsers: (data: any) => {
    let url = `/users?page=${data.page || 0}&limit=${data.limit}&type=${
      data.type
    }`;
    if (data.searchKeyword) {
      url += `&searchKeyword=${data.searchKeyword}`;
    }
    if (data.showActive !== undefined) {
      url += `&showActive=${data.showActive}`;
    }
    if (data.tiers) {
      url += `&tiers=${data.tiers}`;
    }
    if (data.createdAt) {
      url += `&startDate=${data.createdAt}&endDate=${data.createdAt}`;
    }
    if (data.sortBy) {
      url += `&sortBy=${data.sortBy}`;
    }
    if (data.sortOrder) {
      url += `&sortOrder=${data.sortOrder}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getDashboardCharts: (data: any) => {
    let url = `/users/${data.id}/reports?type=${data.type}`;
    if (data.duration) {
      url += `&duration=${data.duration}`;
    }
    if (data.startDate) {
      url += `&startDate=${data.startDate}`;
    }
    if (data.endDate) {
      url += `&endDate=${data.endDate}`;
    }
    return apiClient.get(url).then((r) => r.data);
  },
  fetchUser: (id: string) => {
    return apiClient.get(`/users/${id}`).then((res) => res.data);
  },
  updateUserActive: (id: string, isActive: boolean) => {
    return apiClient.patch(`/users/${id}/status`, { isActive });
  },
  updateUser: (id: string, data: any) => {
    return apiClient.put(`/users/${id}`, data);
  },
  inviteUser: (data: any) => {
    return apiClient.post("/auth/invite", {
      ...data,
      registerUrl: "https://dev.app.stealthseller.co/register",
    });
  },
  userForgotPassword: (id: string, data: any) => {
    return apiClient.post(`/users/${id}/email/update/initiate`, data);
  },
};
