export const extractSellerIdFromUrl = (
  url: string,
  shouldPassRawString?: boolean
) => {
  /*
    For URLs in the format:
    https://www.amazon.com/s?i=merchant-items&me=A33GT9GFT6JRXA&marketplaceID=ATVPDKIKX0DER
    Extract the Seller ID from the &me= parameter.

    For URLs in the format:
    https://www.amazon.com/sp?ie=UTF8&seller=A15C7ICQJML518&isAmazonFulfilled=0&asin=B08WLJN3W7&ref_=olp_merch_name_2
    Extract the Seller ID from the &seller= parameter.

    For URLs in the format:
    https://www.amazon.com/s?me=A15C7ICQJML518&marketplaceID=ATVPDKIKX0DER
    Extract the Seller ID from the &me= parameter.

    For URLs in the format:
    https://keepa.com/#!seller/1-AVWKWK50L5SJS
    Extract the Seller ID from the - character. 
    */
  let sellerId = "";
  // verify string is url and not empty
  if (!url || !url.includes("http")) {
    return url;
  }
  if (url.includes("me=")) {
    sellerId = url.split("me=")[1].split("&")[0];
  } else if (url.includes("&seller=")) {
    sellerId = url.split("&seller=")[1].split("&")[0];
  } else if (url.includes("https://keepa.com/#!seller")) {
    sellerId = url.split("-")[1];
  }
  if (shouldPassRawString && !sellerId) return url;
  return sellerId;
};

export const filteredSellers = (
  sellerList: any[],
  search?: string,
  sorting?: any
) => {
  if (!sellerList) return [];
  if (!search && !sorting?.key) return sellerList;
  let list = [...sellerList];
  if (search) {
    list = list.filter(
      (seller: any) =>
        seller &&
        ((seller?.sellerName || seller?.nickName)
          ?.toLowerCase()
          .includes(search.toLowerCase()) ||
          seller?.sellerId?.toLowerCase().includes(search.toLowerCase()))
    );
  }
  if (sorting?.key) {
    list = list.sort((a: any, b: any) => {
      const aVal =
        sorting.key === "sellerName"
          ? a[sorting.key] || a["nickName"]
          : a[sorting.key];
      const bVal =
        sorting.key === "sellerName"
          ? b[sorting.key] || b["nickName"]
          : b[sorting.key];

      if (aVal < bVal) {
        return sorting.order === "asc" ? -1 : 1;
      }
      if (aVal > bVal) {
        return sorting.order === "asc" ? 1 : -1;
      }
      return 0;
    });
  }
  return list;
};
