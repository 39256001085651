import { useEffect, useRef, useState } from "react";
import { FilterView } from "./components/FilterView";
import moment from "moment";
import { getUser } from "../../helpers/utils";
import { useSeller } from "./context/useSeller";
import { SellerDetails } from "./SellerDetails/SellerDetails";
import { SellerListRowAction } from "./components/SellerListRowAction";
import { DeleteSellerConfirm } from "./components/DeleteSellerConfirm";
import { ReactComponent as SellersIcon } from "../../assets/icons/sellers.svg";
import {
  ArrowDownIcon,
  ArrowUpDownIcon,
  ArrowUpIcon,
  SortAscIcon,
  SortDescIcon,
} from "lucide-react";

import { EmptySeller } from "./components/EmptySeller";
import { SellerFormModal } from "./components/SellerFormModal";
import { RenderTags } from "./components/RenderTags";
import {
  MobileSellerCard,
  MobileSkeletonSellerCard,
} from "./SellerDetails/components/MobileSellerCard";
import clsx from "clsx";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="bg-white [&_td]:py-3 [&_td_div]:rounded-[6px] [&_td]:border-b [&_td]border-b-[#E5E7EB]"
        >
          <td className="pl-3">
            <div className="w-16 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-40 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td className="subscription">
            <div className="w-16 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td className="flex items-center gap-1">
            <div className="w-4 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-5 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-5 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

function Sellers() {
  const [open, setOpen] = useState<boolean>(false);
  const [userView, setUserView] = useState<any>(null);
  const user = getUser();

  const {
    sellerList,
    isLoading,
    state,
    onSearch,
    filters,
    setFilters,
    updateFilters,
    hasMore,
    sorting,
    setSorting,
    loadMore,
  } = useSeller();
  const [removeConfirm, setRemoveConfirm] = useState<any>(null);

  return (
    <div>
      <div className="flex bg-white  justify-between items-center pl-4 py-[13.5px] pr-3 border-b border-b-slate-300">
        <div className="flex items-center gap-6">
          <h2 className="font-grotesk text-[1rem]">Manage Sellers</h2>
        </div>
      </div>
      <div className="p-[1.125rem]">
        <FilterView
          search={state.keyword}
          setSearch={(keyword) => onSearch(keyword)}
          onAddSeller={() => setOpen(true)}
          filters={filters}
          onClear={() => {
            // setFilters(prev => ({...prev, });
            updateFilters({ timeCreated: {} });
          }}
          onChange={(updates) => {
            updateFilters({
              ...updates,
            });
          }}
        />
        <div>
          <div
            className={`mt-5 text-[14px] h-[calc(100vh-116px)] lg:h-[calc(100vh-140px)] overflow-auto px-3 md:px-0`}
          >
            <Table
              sellerList={sellerList}
              hasMore={hasMore}
              isLoading={isLoading}
              sorting={sorting}
              setSorting={setSorting}
              loadMore={loadMore}
              onView={(user) => setUserView(user)}
              onPause={() => {}}
              onActive={() => {}}
              onRemove={(user) => setRemoveConfirm(user)}
              onRowClick={(user) => {
                setUserView(user);
                console.log(user);
                console.log("a");
              }}
            />

            {isLoading === false && !sellerList?.length && (
              <EmptySeller onAddSeller={() => setOpen(true)} />
            )}
          </div>
        </div>
      </div>
      {userView && (
        <SellerDetails
          open={!!userView}
          seller={userView}
          onClose={() => setUserView(null)}
          refetch={() => {
            // refetchSellers();
            // fetchSellers();
          }}
          onSubscription={() => {}}
        />
      )}
      {!!removeConfirm && (
        <DeleteSellerConfirm
          open={!!removeConfirm}
          onClose={() => setRemoveConfirm(null)}
          seller={removeConfirm}
          onSuccess={() => {
            // refetchSellers();
            // fetchSellers();
          }}
        />
      )}
      {open && (
        <SellerFormModal
          open={open}
          setOpen={setOpen}
          onSuccess={() => {
            // refetchSellers();
            // fetchSellers();
          }}
          onSubscription={() => {}}
        />
      )}
    </div>
  );
}

function Table({
  sellerList,
  hasMore,
  isLoading,
  sorting,
  setSorting,
  loadMore,
  onView,
  onPause,
  onActive,
  onRemove,
  onRowClick,
}: {
  sellerList: any;
  hasMore: any;
  isLoading: any;
  sorting: any;
  setSorting: any;
  loadMore: any;
  onView: any;
  onPause: any;
  onActive: any;
  onRemove: any;
  onRowClick?: any;
}) {
  const user = getUser();
  const loadMoreRef = useRef<any>();

  const handleSort = (key: string) => {
    if (sorting.key === key) {
      setSorting({
        key,
        order: sorting.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSorting({
        key,
        order: "asc",
      });
    }
  };

  const observerTarget = useRef(null);

  // load more when ref is reached

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        console.log("entries", entries, hasMore, isLoading);
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, hasMore, isLoading]);

  console.log("sellerList", sellerList);

  return (
    <table
      className="table w-full"
      style={{
        borderCollapse: "separate",
        borderSpacing: 0,
      }}
    >
      <colgroup>
        <col style={{ width: "24%" }} />
        <col style={{ width: "18%" }} />
        <col style={{ width: "20%" }} />
        <col style={{ width: "16%" }} />
        <col style={{ width: "16%" }} />
        <col style={{ width: "6%" }} />
      </colgroup>
      <thead className="[&_td]:border-y [&_td]:border-slate-200 [&_td]:bg-white  [&_td]:py-2">
        <tr className="sticky top-0 z-[1]">
          <td className="rounded-tl-[6px] border-l border-l-slate-200 pl-3">
            <div
              onClick={() => handleSort("name")}
              className="flex gap-1 cursor-pointer items-center text-sm text-[#111827] leading-[23.8px]"
            >
              Name
              {sorting.key === "name" ? (
                <ArrowDownIcon
                  className={`sort-icon w-4 h-4 ${
                    sorting.order === "asc"
                      ? "rotate-[180deg]"
                      : "rotate-[0deg]"
                  }`}
                />
              ) : (
                <ArrowUpDownIcon className="w-4 h-4" />
              )}
            </div>
          </td>
          <td>
            <div className="flex items-center text-sm text-[#111827] leading-[23.8px]">
              Store ID
            </div>
          </td>
          <td>
            <div className="flex items-center text-sm text-[#111827] leading-[23.8px]">
              Total users monitoring
            </div>
          </td>
          <td>
            <div
              onClick={() => handleSort("joinedOn")}
              className="flex gap-1 items-center cursor-pointer text-sm text-[#111827] leading-[23.8px]"
            >
              Added on
              {sorting.key === "joinedOn" ? (
                <ArrowDownIcon
                  className={`sort-icon w-4 h-4 ${
                    sorting.order === "asc"
                      ? "rotate-[180deg]"
                      : "rotate-[0deg]"
                  }`}
                />
              ) : (
                <ArrowUpDownIcon className="w-4 h-4" />
              )}
            </div>
          </td>
          <td>
            <div
              // onClick={() => handleSort("createdAt")}
              className="flex items-center text-sm text-[#111827] leading-[23.8px]"
            >
              Last time posted
              {/* {sorting.key === "createdAt" ? (
                <ArrowDownIcon
                  className={`sort-icon ${
                    sorting.order === "asc"
                      ? "rotate-[180deg]"
                      : "rotate-[0deg]"
                  }`}
                />
              ) : null} */}
            </div>
          </td>
          <td className="rounded-tr-[6px] pr-5 border-r border-r-slate-200">
            Action
          </td>
        </tr>
      </thead>
      <tbody>
        {sellerList?.map((user: any, index: number) => (
          <tr
            key={user.id}
            className={clsx(
              "cursor-pointer bg-white hover:bg-slate-50 [&_td]:py-3 [&_td]:border-b [&_td]border-b-[#E5E7EB] border-r border-l"
            )}
            onClick={() => onRowClick && onRowClick(user)}
          >
            <td className={clsx("name pl-3 border-l")}>
              <div className="flex items-center gap-2.5">
                <h5 className="flex-1">{user?.sellerName || user?.nickName}</h5>
              </div>
            </td>
            <td>
              <div className="flex items-center gap-2">{user?.sellerId}</div>
            </td>
            <td>
              <div className="rounded-[6px] border w-fit border-slate-200 bg-slate-50 px-1.5 py-[2px] gap-1 flex items-center">
                <SellersIcon className="w-3 h-3" />
                <span className="text-xs text-slate-900 font-medium leading-[18px]">
                  {user?.totalUsers || 0}
                </span>
              </div>
            </td>
            <td className="account-manager">
              {moment(user?.createdAt).utc().local().format("MMM DD,YYYY")}
            </td>
            <td className="account-manager">
              {/* if lastTimePosted is less than 10 minutes show Now */}

              {user?.lastTimePosted
                ? // ? moment(user?.lastTimePosted)
                  //     .utc()
                  //     .local()
                  //     .format("MMM DD,YYYY HH:mm")
                  moment(user?.lastTimePosted).utc().local().fromNow()
                : "-"}
            </td>

            <td className="pr-5 border-r">
              <SellerListRowAction
                onView={() => onView(user)}
                status={user?.status}
              />
            </td>
          </tr>
        ))}
        {isLoading && <LoadingSkeleton />}

        <div ref={observerTarget}></div>
        <div className="p-5"></div>
      </tbody>
    </table>
  );
}

export default Sellers;
