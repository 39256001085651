import { SearchInput } from "../../../../components/Search";
import { FilterModal } from "./FilterModal";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { CategorySelect } from "./CategorySelect";
import { useState } from "react";
import { NumberFilterInput } from "./NumberFilterInput";
import { DateSelect } from "./DateSelect";
import { useProductFeed } from "../../context/product-feed-context";
import { FILTER_NAME } from "../constant";
import moment from "moment";

export function FilterViewUI({
  onSearch,
  searchState,
  setProductFilter,
  productFilter,
  onClearFilter,
  onApply,
  selectedSeller,
  total,
  onClearAllFilters,
  showTotal = true,
  appliedFilter,
  setAppliedFilter,
}) {
  const hasSeller = selectedSeller;
  const [filtersToShow, setFiltersToShow] = useState<string[]>([]);

  const clearFilter = (filtersToShow, productFilter) => {
    // need to clear filters other than the selected ones
    let newFilters: any = {};
    filtersToShow.forEach((filter) => {
      newFilters[filter] =
        productFilter[filter] || filter === "categories" ? [] : {};
    });
    setProductFilter(newFilters);
  };

  const handleChange = (filter) => {
    let newFiltersToShow = [...filtersToShow];
    if (filtersToShow.includes(filter.name)) {
      newFiltersToShow = filtersToShow.filter((item) => item !== filter.name);
    } else {
      newFiltersToShow.push(filter.name);
    }
    setFiltersToShow(newFiltersToShow);
    clearFilter(newFiltersToShow, productFilter);
  };

  const handleClearAll = () => {
    setProductFilter({});
    setFiltersToShow([]);
    onClearAllFilters();
  };

  const renderFilters = () => {
    return (
      <>
        {filtersToShow.includes(FILTER_NAME.BUY_BOX) && (
          <NumberFilterInput
            label="Buy Box"
            onChange={(filter) =>
              setProductFilter((prev) => ({ ...prev, buyBox: filter }))
            }
            value={productFilter?.buyBox || {}}
            suffix="prices"
            name="BuyBoxPrice"
            showDollar={true}
            onClear={() => {
              onClearFilter("BuyBoxPrice");
              setProductFilter((prev) => ({ ...prev, buyBox: {} }));
            }}
            onApply={() => {
              onApply({ ...productFilter?.buyBox, name: "BuyBoxPrice" });
            }}
            appliedValue={appliedFilter?.BuyBoxPrice}
          />
        )}
        {filtersToShow.includes(FILTER_NAME.CATEGORIES) && (
          <CategorySelect
            value={productFilter?.categories}
            onChange={(value) =>
              setProductFilter((prev) => ({ ...prev, categories: value }))
            }
            onClear={() => {
              setProductFilter((prev) => ({ ...prev, categories: [] }));
              onClearFilter("categories");
            }}
            onApply={() => {
              onApply({
                name: "categories",
                value: productFilter?.categories,
              });
            }}
            appliedValue={appliedFilter?.categories?.value || []}
          />
        )}
        {filtersToShow.includes(FILTER_NAME.OFFERS) && (
          <NumberFilterInput
            label="Offers"
            onChange={(filter) =>
              setProductFilter((prev) => ({ ...prev, offers: filter }))
            }
            suffix="offers"
            name="OffersCount"
            onClear={() => {
              setProductFilter((prev) => ({ ...prev, offers: {} }));
              onClearFilter("OffersCount");
            }}
            value={productFilter?.offers || {}}
            onApply={() => {
              onApply({ ...productFilter?.offers, name: "OffersCount" });
            }}
            appliedValue={appliedFilter?.OffersCount}
          />
        )}
        {filtersToShow.includes(FILTER_NAME.SALES_RANK) && (
          <NumberFilterInput
            label="Sales Rank"
            onChange={(filter) =>
              setProductFilter((prev) => ({ ...prev, salesRank: filter }))
            }
            suffix="sales rank"
            name="SalesRank"
            onClear={() => {
              setProductFilter((prev) => ({ ...prev, salesRank: {} }));
              onClearFilter("SalesRank");
            }}
            value={productFilter.salesRank}
            onApply={() => {
              onApply({ ...productFilter?.salesRank, name: "SalesRank" });
            }}
            appliedValue={appliedFilter?.SalesRank}
          />
        )}
        {filtersToShow.includes(FILTER_NAME.MONTHLY_SOLD) && (
          <NumberFilterInput
            label="Monthly Sales"
            onChange={(filter) =>
              setProductFilter((prev) => ({ ...prev, monthlySold: filter }))
            }
            suffix="sales"
            name="MonthlySales"
            value={productFilter.monthlySold}
            onClear={() => {
              setProductFilter((prev) => ({ ...prev, monthlySold: {} }));
              onClearFilter("MonthlySales");
            }}
            onApply={() => {
              onApply({
                ...productFilter?.monthlySold,
                name: "MonthlySales",
              });
            }}
            appliedValue={appliedFilter?.MonthlySales}
          />
        )}
        {filtersToShow.includes(FILTER_NAME.TIME_POSTED) && (
          <DateSelect
            name="TimePosted"
            onDateChange={(filter) => {
              setProductFilter((prev) => ({ ...prev, timePosted: filter }));
            }}
            value={productFilter.timePosted}
            onApply={() => {
              if (
                productFilter?.timePosted?.startDate &&
                productFilter?.timePosted?.endDate
              ) {
                onApply({
                  name: "timePosted",
                  startDate: moment(
                    productFilter?.timePosted?.startDate
                  ).format(),
                  endDate: moment(productFilter?.timePosted?.endDate).format(),
                });
              }
            }}
            appliedValue={appliedFilter?.timePosted}
            onClear={() => {
              setProductFilter((prev) => ({ ...prev, timePosted: {} }));
              onClearFilter("timePosted");
            }}
            className="!shadow-none"
          />
        )}

        {filtersToShow.length > 0 && (
          <button
            onClick={handleClearAll}
            className="text-[#09090B] hover:text-[#09090B]/80 text-sm font-medium flex items-center gap-1.5 !h-fit"
          >
            Clear all
            <CloseIcon className="w-4 h-4" />
          </button>
        )}
      </>
    );
  };

  const renderSearchInput = () => {
    return (
      <SearchInput
        onChange={(e) => onSearch(e.target.value)}
        value={searchState.keyword}
        className="flex-1 lg:flex-[unset] !m-[0px]"
      />
    );
  };

  return (
    <>
      <div className="flex justify-between items-stretch md:gap-2 lg:gap-20 flex-1 items-center">
        <div className="hidden lg:flex gap-2 items-center mt-[2px] flex-wrap">
          {renderSearchInput()}
          {renderFilters()}
        </div>
        <div className="flex lg:hidden gap-2 items-center flex-wrap">
          {renderSearchInput()}
        </div>

        <div className="flex flex-col justify-between">
          <FilterModal
            onClear={handleClearAll}
            filters={filtersToShow}
            onChange={handleChange}
          />

          {/* {showTotal && hasSeller && (
            <div className="hidden lg:block text-right">
              <span className="text-[#09090B] text-right text-xs font-medium">
                {total} product{total > 1 ? "s" : ""}
              </span>
            </div>
          )} */}
        </div>
      </div>
      <div className="lg:hidden flex gap-2 items-center whitespace-nowrap overflow-auto py-2">
        {renderFilters()}
      </div>
    </>
  );
}

export function FilterView() {
  const {
    productFilter,
    setProductFilter,
    sellersLoading,
    sellers,
    selectedSeller,
    onClearFilter,
    onClearAllFilters,
    onApply,
    onSearch,
    searchState,
    total,
    appliedFilter,
    setAppliedFilter,
  } = useProductFeed() as any;

  return (
    <div className="pl-3 pr-3 md:pr-[18px]">
      <FilterViewUI
        onApply={onApply}
        onClearAllFilters={onClearAllFilters}
        onClearFilter={onClearFilter}
        onSearch={onSearch}
        productFilter={productFilter}
        searchState={searchState}
        selectedSeller={selectedSeller}
        total={total}
        setProductFilter={setProductFilter}
        appliedFilter={appliedFilter}
        setAppliedFilter={setAppliedFilter}
      />
    </div>
  );
}
