import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";

export function Chip({
  children,
  onClick,
  className,
}: {
  children: React.ReactNode;
  onClick?: (e: any) => void;
  className?: string;
}) {
  return (
    <div
      className={`w-fit text-xs flex bg-white items-center gap-1 px-1.5 py-[2px] rounded-[6px] border border-slate200 ${className}`}
    >
      {children}
      {onClick && (
        <button onClick={onClick}>
          <CloseIcon className="w-3 h-3" />
        </button>
      )}
    </div>
  );
}
