import { TextField } from "@radix-ui/themes";
import { MagnifyingGlassIcon, Cross1Icon } from "@radix-ui/react-icons";

export function SearchInput({
  className = "",
  value,
  onChange,
}: {
  className?: string;
  value?: string;
  onChange?: (e: any) => void;
}) {
  const clearSearch = () => {
    onChange && onChange({ target: { value: "" } });
  };
  return (
    <TextField.Root
      placeholder="Search"
      className={`search-input !border-slate-200 !outline-offset-[1.5px] !shadow-[inset_0_0_0_1px_#E2E8F0] !rounded-[6px] !bg-[white] !px-3 !py-1 ${className}`}
      value={value}
      onChange={onChange}
      style={{
        backgroundClip: "unset",
      }}
    >
      <TextField.Slot className="!mr-1.5 !px-0">
        <MagnifyingGlassIcon height="16" width="16" />
      </TextField.Slot>
      {value && (
        <TextField.Slot
          onClick={clearSearch}
          className="!mr-1.5 !px-0 cursor-pointer"
        >
          <Cross1Icon height="12" width="12" className=" cursor-pointer" />
        </TextField.Slot>
      )}
    </TextField.Root>
  );
}
