import { ReactComponent as InfoIcon } from "../assets/icons/info.svg";
export function ErrorView({ message }) {
  return (
    <div className="px-1.5 py-0.5 rounded-md justify-start items-center gap-1 flex">
      <InfoIcon className="w-3 h-3 [&_path]:stroke-[#7F1D1D]" />
      <div className="text-red-900 text-xs font-medium leading-[18px]">
        {message}
      </div>
    </div>
  );
}
