import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../@/components/ui/dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { ReactComponent as BackIcon } from "../assets/icons/arrow.svg";
import { Button } from "../@/components/ui/button";

export default function Modal({
  children,
  noClose,
  open,
  title,
  handleModal,
  onBack,
  contentClassName = "",
}: {
  children: React.ReactNode;
  noClose?: boolean;
  open: boolean;
  title: string;
  handleModal?: () => void;
  onBack?: () => void;
  contentClassName?: string;
}) {
  return (
    <Dialog open={open} onOpenChange={handleModal}>
      <DialogContent
        className={`!w-[calc(100vw-40px)] !max-w-[420px] !rounded-lg !p-5 md:!p-6 ${contentClassName}`}
      >
        <DialogHeader className="flex justify-between w-full">
          <DialogTitle className="font-grotesk text-[1.25rem] font-[500] flex justify-between tracking-normal">
            <div className="flex items-center gap-3">
              {onBack && (
                <Button
                  onClick={onBack}
                  className="h-fit w-fit p-1"
                  variant="outline"
                >
                  <BackIcon />
                </Button>
              )}
              <h3 className="leading-[120%]">{title}</h3>
            </div>
            {!noClose && (
              <DialogClose>
                <Cross2Icon />
              </DialogClose>
            )}
          </DialogTitle>
          <DialogDescription className="!mt-0 text-start">
            {children}
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
