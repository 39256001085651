import { useEffect, useRef, useState } from "react";
import { FilterView } from "./components/FilterView";
import moment from "moment";
import { getUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import { useSeller } from "./context/useSeller";
import { StatusButton } from "./components/StatusButton";
import { SellerDetails } from "./SellerDetails/SellerDetails";
import { SellerListRowAction } from "./components/SellerListRowAction";
import { DeleteSellerConfirm } from "./components/DeleteSellerConfirm";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/arrow-down.svg";
import { tagsToSlice } from "./components/TagsSelect";
import hotToast from "react-hot-toast";
import { toast } from "../../../@/components/ui/use-toast";
import { useUser } from "../../auth/context/user-context";
import { useNavigate } from "react-router-dom";
import { EmptySeller } from "./components/EmptySeller";
import { SellerFormModal } from "./components/SellerFormModal";
import { RenderTags } from "./components/RenderTags";
import {
  MobileSellerCard,
  MobileSkeletonSellerCard,
} from "./SellerDetails/components/MobileSellerCard";
import { StatusFilterSelect } from "./components/StatusFilterSelect";
import { Button } from "../../../@/components/ui/button";
import Modal from "../../../components/Modal";
import { subscriptionList } from "../../../helpers/consts";
import { getTotalSellers } from "../Users";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="bg-white [&_td]:py-3 [&_td_div]:rounded-[6px] [&_td]:border-b [&_td]border-b-[#E5E7EB]"
        >
          <td className="pl-3">
            <div className="w-16 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-40 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td className="subscription">
            <div className="w-16 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td className="subscription">
            <div className="w-20 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td className="flex items-center gap-1">
            <div className="w-16 h-4 bg-slate-200 animate-pulse transition duration-50" />
            <div className="w-16 h-4 bg-slate-200 animate-pulse transition duration-50" />
            <div className="w-4 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-5 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

function Sellers({ user, activeSellersCount, tagList, tier }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [userView, setUserView] = useState<any>(null);
  const {
    sellerList,
    loading,
    sorting,
    search,
    fetchSellers,
    setSorting,
    setSearch,
    filters,
    setFilters,
  } = useSeller(user.id);
  const [removeConfirm, setRemoveConfirm] = useState<any>(null);

  const handlePause = async (seller) => {
    try {
      hotToast.loading("Pausing seller...");
      const response = await api.updateSeller(user.id, {
        id: seller.id,
        status: "P",
      });
      hotToast.dismiss();
      if ([200, 201].includes(response.status)) {
        toast({
          title: "Seller paused successfully",
        });
        fetchSellers(user.id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleActive = async (seller) => {
    try {
      hotToast.loading("Activating seller...");
      const response = await api.updateSeller(user.id, {
        id: seller.id,
        status: "A",
      });
      hotToast.dismiss();
      if ([200, 201].includes(response.status)) {
        toast({
          title: "Seller activated successfully",
        });
        fetchSellers(user.id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleRemove = async (user) => {
    setRemoveConfirm(user);
  };

  const onSubscription = () => {
    navigate("/subscription");
  };

  const handleSort = (key: string) => {
    if (sorting.key === key) {
      setSorting({
        key,
        order: sorting.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSorting({
        key,
        order: "asc",
      });
    }
  };

  return (
    <div>
      <div className="hidden lg:flex justify-between items-center md:mt-8">
        <div className="flex items-center gap-2">
          <h2 className="font-grotesk text-[1rem]">Sellers</h2>
          <Button
            variant={"outline"}
            color="gray"
            onClick={() => {}}
            className={`px-1.5 h-6 flex gap-1 items-center rounded-[6px] bg-slate-50 border border-slate200`}
          >
            <span className="text-[#4B5563] text-xs font-medium">
              {activeSellersCount || 0}/
              {getTotalSellers(tier, subscriptionList)}
            </span>
          </Button>
        </div>
      </div>
      <div className="mt-4">
        {/* <FilterView
          search={search}
          setSearch={setSearch}
          onAddSeller={() => setOpen(true)}
          filters={filters}
          onClear={() => setFilters({})}
          onChange={(updates) => {
            setFilters({
              ...filters,
              ...updates,
            });
          }}
        /> */}
        <div>
          <div className={`mt-3 text-[14px] overflow-auto`}>
            <table
              className="hidden lg:table w-full"
              style={{
                borderCollapse: "separate",
                borderSpacing: 0,
              }}
            >
              <colgroup>
                <col style={{ width: "24%" }} />
                <col style={{ width: "28%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "22%" }} />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead className="[&_td]:border-y [&_td]:border-slate-200 [&_td]:bg-white  [&_td]:py-2">
                <tr className="sticky top-0 z-[1]">
                  <td className="rounded-tl-[6px] border-l border-l-slate-200 pl-3">
                    <div
                      onClick={() => handleSort("sellerName")}
                      className="flex gap-1 items-center cursor-pointer text-sm text-[#111827] leading-[23.8px]"
                    >
                      Name
                      {sorting.key === "sellerName" ? (
                        <ArrowDownIcon
                          className={`sort-icon ${
                            sorting.order === "asc"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center text-sm text-[#111827] leading-[23.8px]">
                      Store ID
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() => handleSort("createdAt")}
                      className="flex cursor-pointer items-center text-sm text-[#111827] leading-[23.8px]"
                    >
                      Added on
                      {sorting.key === "createdAt" ? (
                        <ArrowDownIcon
                          className={`sort-icon ${
                            sorting.order === "asc"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() => handleSort("status")}
                      className="flex gap-1 items-center cursor-pointer text-sm text-[#111827] leading-[23.8px]"
                    >
                      Status
                      {sorting.key === "status" ? (
                        <ArrowDownIcon
                          className={`sort-icon ${
                            sorting.order === "asc"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center text-sm text-[#111827] leading-[23.8px]">
                      Tags
                    </div>
                  </td>
                  <td className="rounded-tr-[6px] pr-5 border-r border-r-slate-200">
                    Action
                  </td>
                </tr>
              </thead>
              <tbody>
                {sellerList?.map((user: any) => (
                  <tr
                    key={user.id}
                    className="cursor-pointer bg-white hover:bg-slate-50 [&_td]:py-3 [&_td]:border-b [&_td]border-b-[#E5E7EB]"
                    onClick={() => setUserView(user)}
                  >
                    <td className="name pl-3">
                      <div className="flex items-center gap-2.5">
                        <h5 className="flex-1">
                          {user?.sellerName || user?.nickName}
                        </h5>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center gap-2">
                        {user?.sellerId}
                      </div>
                    </td>
                    <td className="account-manager">
                      {moment(user?.createdAt)
                        .utc()
                        .local()
                        .format("MMM DD,YYYY")}
                    </td>
                    <td className="account-executive">
                      <StatusButton status={user?.status} />
                    </td>
                    <td className="mrr">
                      {user?.tags?.[0] !== "" ? (
                        <div className="flex flex-wrap items-center gap-1">
                          <RenderTags tags={user?.tags} visibleCount={1} />
                        </div>
                      ) : (
                        ""
                      )}
                    </td>

                    <td className="pr-5">
                      <SellerListRowAction
                        onView={() => setUserView(user)}
                        onPause={() => handlePause(user)}
                        onActive={() => handleActive(user)}
                        onRemove={() => handleRemove(user)}
                        status={user?.status}
                      />
                    </td>
                  </tr>
                ))}
                {loading && <LoadingSkeleton />}
                <div className="h-5"></div>
              </tbody>
            </table>
            <div className="lg:hidden flex flex-col gap-3 pb-4">
              {sellerList?.map((user: any) => (
                <MobileSellerCard
                  seller={user}
                  key={user.id}
                  setUserView={setUserView}
                  handlePause={handlePause}
                  handleActive={handleActive}
                  handleRemove={handleRemove}
                />
              ))}
              {loading && (
                <>
                  {Array.from({ length: 7 }).map((_, i) => (
                    <MobileSkeletonSellerCard key={i} />
                  ))}
                </>
              )}
            </div>
            {loading === false && !sellerList?.length && <EmptySeller />}
          </div>
        </div>
      </div>
      {userView && (
        <SellerDetails
          open={!!userView}
          seller={userView}
          onClose={() => setUserView(null)}
          refetch={() => {
            // refetchSellers();
            fetchSellers(user?.id);
          }}
          onSubscription={onSubscription}
          user={user}
          tagList={tagList}
        />
      )}
      {!!removeConfirm && (
        <DeleteSellerConfirm
          open={!!removeConfirm}
          onClose={() => setRemoveConfirm(null)}
          seller={removeConfirm}
          onSuccess={() => {
            fetchSellers(user?.id);
          }}
        />
      )}
      {open && (
        <SellerFormModal
          open={open}
          setOpen={setOpen}
          onSuccess={() => {
            fetchSellers(user?.id);
          }}
          onSubscription={onSubscription}
          tagList={tagList}
          user={user}
        />
      )}
    </div>
  );
}

export default Sellers;
