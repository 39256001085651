import React, { useEffect, useState } from "react";
import { getUser } from "../../../helpers/utils";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../helpers/api";

export const UserContext = React.createContext({});

export function UserProvider({ children }) {
  const [categories, setCategories] = useState<string[]>([]);
  const user = getUser();
  const {
    data: activeSubscription,
    isLoading,
    refetch,
  } = useQuery(["subscription"], () => api.getUserSubscription(user.id));

  const {
    data: sellerList,
    isLoading: sellerLoading,
    refetch: refetchSellers,
  } = useQuery(["sellers"], () => api.getSellers(user.id), {
    enabled: !!user?.id,
  });

  const fetchCategories = async (userId: string) => {
    try {
      const data = await api.getCategories(userId);
      console.log(data, "categories");
      if (data) {
        setCategories(
          data.map((item) => item.name).sort((a, b) => a.localeCompare(b))
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchCategories(user?.id);
    }
  }, [user?.id]);

  const totalActiveUsers = sellerList?.filter(
    (seller) => seller.status === "A"
  )?.length;

  const values = {
    activeSubscription,
    isLoading,
    refetch,
    totalActiveUsers,
    refetchSellers,
    categories,
  };

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}

export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useTags must be used within a UserProvider");
  }
  return context;
}
