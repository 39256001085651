import { DialogClose } from "@radix-ui/react-dialog";
import { AspectRatio, Dialog } from "@radix-ui/themes";
import { ArrowLeftIcon, ArrowRightIcon } from "@radix-ui/react-icons";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { Button } from "../../../../@/components/ui/button";
import { useState } from "react";

export function ProductImageViewer({
  children,
  images,
}: {
  children: any;
  images?: string[];
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleImageChange = (index) => setActiveIndex(index);
  return (
    <Dialog.Root>
      <Dialog.Trigger>{children}</Dialog.Trigger>

      <Dialog.Content maxWidth="450px">
        <DialogClose className="absolute top-[18px] right-[18px] z-[1]">
          <CloseIcon />
        </DialogClose>
        <div className="absolute top-0 bottom-0 flex flex-col justify-center left-2">
          <Button
            variant="outline"
            color="gray"
            className="bg-slate-200 rounded-full !px-0 !w-[30px] !h-[30px]"
            onClick={() => handleImageChange(activeIndex - 1)}
            disabled={activeIndex === 0}
          >
            <ArrowLeftIcon />
          </Button>
        </div>
        <div className="p-4 px-8">
          <AspectRatio ratio={225 / 225}>
            <img
              src={images?.[activeIndex]}
              alt="product"
              className="w-full h-full object-contain"
            />
          </AspectRatio>
        </div>

        <div className="absolute top-0 bottom-0 flex flex-col justify-center right-2">
          <Button
            variant="outline"
            color="gray"
            className="bg-slate-200 rounded-full !px-0 !w-[30px] !h-[30px]"
            onClick={() => handleImageChange(activeIndex + 1)}
            disabled={activeIndex === (images?.length || 0) - 1}
          >
            <ArrowRightIcon />
          </Button>
        </div>
        <div className="flex items-center gap-[5px] justify-center">
          {images?.map((item, i) => (
            <button
              key={item}
              className="w-10 h-10 p-1 border border-slate-300 rounded-[6px]"
              onClick={() => handleImageChange(i)}
            >
              <AspectRatio ratio={225 / 225}>
                <img
                  src={item}
                  alt="product"
                  className="w-full h-full object-contain"
                />
              </AspectRatio>
            </button>
          ))}
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
