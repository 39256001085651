import { Link } from "react-router-dom";
import LayoutSvg from "../assets/images/auth-layout.svg";
import LayoutPng from "../assets/images/auth-layout-min.png";
import LogoIcon from "../assets/icons/logo.svg";
import { Button } from "../@/components/ui/button";
import useViewportHeight from "../helpers/hooks/useViewportHeight";

export function Layout({ children }: { children: React.ReactNode }) {
  // check if location is /login
  const isLogin = window.location.pathname === "/login";
  const viewportHeight = useViewportHeight();

  return (
    <div
      className="w-full lg:h-screen lg:overflow-hidden flex"
      style={{ height: viewportHeight }}
    >
      <div className="flex-1">
        <Link to="/" className="mx-auto lg:hidden absolute left-4 top-4">
          <img src={LogoIcon} alt="logo" className="h-[1.375rem]" />
        </Link>
        {!isLogin && (
          <Button variant="link" className="lg:block hidden">
            <Link
              to="/"
              className="absolute top-[1.875rem] left-[1.875rem] text-sm font-[600] font-inter"
            >
              Login
            </Link>
          </Button>
        )}
        {children}
      </div>
      <div className="flex-1 lg:block hidden relative bg-[#060606]">
        {/* overlay png behind svg */}
        <img
          src={LayoutPng}
          alt="layout"
          className="w-full h-[100vh] object-cover absolute left-0 z-[1]"
          loading="lazy"
        />

        <img
          src={LayoutSvg}
          alt="layout"
          className="w-full h-[100vh] object-cover absolute left-0 z-[2]"
          loading="lazy"
        />
      </div>
    </div>
  );
}
