import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "../../../../@/components/ui/drawer";
import { Button } from "../../../../@/components/ui/button";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { ReactComponent as PencilIcon } from "../../../../assets/icons/pencil.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash.svg";
import { ReactComponent as StoreIcon } from "../../../../assets/icons/store.svg";
import { ReactComponent as PauseIcon } from "../../../../assets/icons/pause.svg";
import { ReactComponent as PlayIcon } from "../../../../assets/icons/play.svg";
import { ReactComponent as BlockIcon } from "../../../../assets/icons/block.svg";
import { ReactComponent as CalendarPlusIcon } from "../../../../assets/icons/calendar-plus.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy.svg";
import hotToast from "react-hot-toast";

import { sellerStatus } from "../../../product-feed/SellerList/constant";
import moment from "moment";
import { Products } from "./Products";
import { useCallback, useEffect, useState } from "react";
import { SellerFormModal } from "../components/SellerFormModal";
import { getUser } from "../../../../helpers/utils";
import { api } from "../../../../helpers/api";
import { DeleteSellerConfirm } from "../components/DeleteSellerConfirm";
import Spinner from "../../../../components/Spinners";
import { SellerDetailsAction } from "./components/SellerDetailsAction";
import { useSellerProduct } from "../context/seller-product-context";
import { useToast } from "../../../../@/components/ui/use-toast";
import { ColorBoxIcon } from "../../../product-feed/components/ColorBoxIcon";
import { StatusButton } from "../components/StatusButton";

export function SellerDetails({
  open,
  seller,
  onClose,
  refetch,
  onSubscription,
  user,
  tagList,
}) {
  const { products, productsLoading, setSelectedSeller, loadMore, total } =
    useSellerProduct() as any;
  const { toast } = useToast();
  const [edit, setEdit] = useState(false);
  const [removeConfirm, setRemoveConfirm] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const [isStatusSaving, setIsStatusSaving] = useState(false);
  const [sellerDetails, setSellerDetails] = useState<any>(null);

  // use query to get products for selected seller
  // const { data: productsData, isLoading: productsLoading } = useQuery(
  //   ["seller-products", seller?.sellerDbId],
  //   () => api.getProducts(seller?.sellerDbId, { page: 0, limit: 20 }),
  //   {
  //     enabled: !!seller?.sellerDbId,
  //   }
  // );

  const statusInfo = sellerStatus.find(
    (color) => color.name === sellerDetails?.status
  );

  const fetchSellerDetails = async (userId: string, id: string) => {
    try {
      setLoading(true);
      const response = await api.fetchSeller(userId, id);
      setSellerDetails(response);
      setLoading(false);
    } catch (e) {}
  };

  const handleRefresh = () => {
    fetchSellerDetails(user.id, seller?.id);
    refetch();
  };

  const handleDeactivate = async () => {};

  const handlePause = async () => {
    try {
      setIsStatusSaving(true);
      const response = await api.updateSeller(user.id, {
        id: sellerDetails.id,
        status: "P",
      });
      setIsStatusSaving(false);
      if ([200, 201].includes(response.status)) {
        toast({
          title: "Seller paused successfully",
        });
        setSellerDetails((prev) => ({ ...prev, status: "P" }));
        refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleActive = async () => {
    try {
      setIsStatusSaving(true);
      const response = await api.updateSeller(user.id, {
        id: sellerDetails.id,
        status: "A",
      });
      setIsStatusSaving(false);
      if ([200, 201].includes(response.status)) {
        toast({
          title: "Seller activated successfully",
        });
        setSellerDetails((prev) => ({ ...prev, status: "A" }));
        refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCopy = async (sellerId: string) => {
    console.log(sellerId, "see seller id");
    navigator.clipboard.writeText(sellerId).then(
      () => {
        toast({
          description: `Seller ID: ${sellerDetails?.sellerId} copied to clipboard`,
        });
      },
      () => {
        toast({
          description: "Failed to copy seller ID",
          variant: "destructive",
        });
      }
    );
  };

  useEffect(() => {
    if (open && seller?.id && user.id) {
      fetchSellerDetails(user.id, seller?.id);
      setSelectedSeller(seller);
    }
  }, [open, seller, user.id]);

  return (
    <Drawer open={open} onOpenChange={onClose}>
      <DrawerContent className="bg-white radix-themes !outline-none flex flex-col rounded-t-[10px] h-[96vh] mt-24 fixed bottom-0 left-0 right-0">
        {loading ? (
          <div className="[&_svg]:text-slate-900 h-full w-full flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="lg:max-w-[1030px] w-full mx-auto pt-5 p-4 md:p-10">
              <div>
                <h2 className="font-grotesk text-lg font-medium text-black">
                  Seller details
                </h2>
                <div>
                  <Button
                    onClick={onClose}
                    variant="link"
                    className="!no-underline absolute top-5 right-5 pr-0 pt-0"
                  >
                    <CloseIcon className="w-4 h-4" />
                  </Button>
                </div>
              </div>
              <div className="mt-[18px] flex items-start gap-[14px] border border-[#D1D5DB] rounded-md p-3 md:p-4">
                {/* <div className="!w-10 md:!w-[87px] !h-10 md:!h-[87px] bg-[#EFF3FF] rounded-[6px] flex justify-center items-center">
                    <StoreIcon className="w-5 md:w-[35px] h-5 md:h-[35px]" />
                  </div> */}
                <div className="flex-1 flex justify-between gap-[14px]">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <span className="text-lg text-[#1E293B] font-medium font-grotesk">
                        {sellerDetails?.name ||
                          sellerDetails?.sellerName ||
                          sellerDetails?.nickName}
                      </span>
                      <StatusButton status={sellerDetails?.status} />
                    </div>
                    <button
                      onClick={() => handleCopy(sellerDetails?.sellerId)}
                      className="flex w-fit items-center flex-row gap-1 rounded-[6px] border px-1.5 py-[2px] bg-white border-slate-200"
                    >
                      <span className="text-xs text-slate-500 font-medium">
                        {sellerDetails?.sellerId}
                      </span>
                      <CopyIcon className="w-3 h-3 text-slate-500 [&_path]:fill-slate-500" />
                    </button>
                    <div className="flex flex-wrap gap-1.5">
                      {sellerDetails?.tags?.map((tag, i) => (
                        <div
                          key={i}
                          className="bg-slate-50 w-fit text-xs font-medium leading-[20px] rounded-[6px] border border-slate-200 px-2 py-[2px]"
                        >
                          {tag}
                        </div>
                      ))}
                    </div>
                    {/* <div className="md:hidden flex w-fit self-end items-center gap-1 rounded-[6px] border border-slate-200 bg-slate-50 px-1.5 py-[2px]">
                      <CalendarPlusIcon />
                      <span className="text-xs font-medium leading-[18px] text-slate-500">
                        Joined on{" "}
                        {moment(sellerDetails?.createdAt).format(
                          "MMM DD, YYYY"
                        )}
                      </span>
                    </div> */}
                  </div>
                  <div className="flex flex-col justify-between">
                    <div className="md:hidden">
                      <SellerDetailsAction
                        onEdit={() => setEdit(true)}
                        onRemove={() => setRemoveConfirm(seller)}
                      />
                    </div>
                    <div className="hidden md:flex items-center gap-2">
                      {/* <Button
                          className="!h-7 text-xs font-medium opacity-50 flex items-center gap-1"
                          variant="outline"
                          onClick={() => handleDeactivate()}
                        >
                          <BlockIcon className="w-4 h-4" />
                          Ban
                        </Button> */}
                      <Button
                        variant={"outline"}
                        className="!h-7 text-xs font-medium flex items-center gap-1 "
                        onClick={
                          sellerDetails?.status === "A"
                            ? handlePause
                            : handleActive
                        }
                      >
                        {sellerDetails?.status === "A" ? (
                          <PauseIcon />
                        ) : (
                          <PlayIcon />
                        )}
                        <span>
                          {sellerDetails?.status === "A" ? "Pause" : "Activate"}
                        </span>
                        {isStatusSaving && (
                          <Spinner className="!h-[14px] !w-[14px] !text-slate-900" />
                        )}
                      </Button>
                      <Button
                        onClick={() => setEdit(true)}
                        className="!h-7 text-xs font-medium flex items-center gap-1"
                      >
                        <PencilIcon className="w-4 h-4" />
                        Edit
                      </Button>
                      {/* <div className="border-l border-l-[#D1D5DB] h-[14px]" />
                        <Button
                          variant="link"
                          className="!no-underline gap-1.5 !pr-0"
                          onClick={() => setRemoveConfirm(seller)}
                        >
                          <TrashIcon className="w-5 h-5" />
                          <span className="text-[#030712] text-sm font-medium leading-[23.8px]">
                            Delete
                          </span>
                        </Button> */}
                    </div>

                    <div className="flex w-fit self-end items-center gap-1 rounded-[6px] border border-slate-200 bg-slate-50 px-1.5 py-[2px]">
                      <CalendarPlusIcon />
                      <span className="text-xs font-medium leading-[18px] text-slate-500">
                        Joined on{" "}
                        {moment(sellerDetails?.createdAt).format(
                          "MMM DD, YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <Products
                seller={seller}
                products={products || []}
                isLoading={productsLoading}
                loadMore={loadMore}
                total={total}
              />
            </div>
          </>
        )}
        {edit && sellerDetails && (
          <SellerFormModal
            open={edit}
            setOpen={setEdit}
            onSuccess={handleRefresh}
            seller={sellerDetails}
            onSubscription={onSubscription}
            tagList={tagList}
            user={user}
          />
        )}
        {!!removeConfirm && (
          <DeleteSellerConfirm
            open={!!removeConfirm}
            onClose={() => setRemoveConfirm(null)}
            seller={removeConfirm}
            onSuccess={() => {
              refetch();
              onClose();
            }}
          />
        )}
      </DrawerContent>
    </Drawer>
  );
}
