// import DatePickerComponent from "react-datepicker";
import { ReactComponent as RoundedPlusIcon } from "../../../../assets/icons/rounded-plus.svg";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import { Popover } from "@radix-ui/themes";
import moment from "moment";
import { Button } from "../../../../@/components/ui/button";
import { Calendar } from "../../../../@/components/ui/calendar";

export function JoinedOnSelect({
  className = "",
  name,
  onDateChange,
  value,
  // onApply,
  onClear,
  appliedValue,
  setFilters,
  filters,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState<any>("");

  useEffect(
    () => {
      if (date?.selected) {
        // console.log(value);
        onDateChange({
          name: "createdAt",
          selected: date?.selected,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date]
  );

  return (
    <Popover.Root>
      <Popover.Trigger className="w-fit !px-2 !py-2 !rounded-[6px] !h-8">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)] flex items-center gap-2 border border-dashed border-slate-200 rounded-[6px] bg-white px-[13px] py-[5px]"
        >
          <div className="flex gap-2 items-center">
            <RoundedPlusIcon className="w-4 h-4" />
            <span className="text-xs font-medium leading-[18px] text-slate-900">
              Joined On
            </span>
          </div>
          <div className="border-l border-l-slate-200 h-4" />
          <div className="bg-slate-50 leading-[18px] rounded-[6px] py-[2px] px-1.5 text-slate-900 text-xs">
            {appliedValue
              ? moment(appliedValue).format("MMM DD, YYYY")
              : "All time"}
          </div>
        </button>
      </Popover.Trigger>
      <Popover.Content
        className="!p-0 !overflow-visible"
        // minWidth="auto"
      >
        <div className={`date-picker w-max ${className}`}>
          <div className="hidden md:flex">
            <Calendar
              initialFocus
              mode="single"
              defaultMonth={date}
              selected={date}
              onSelect={setDate}
              numberOfMonths={2}
            />
          </div>
          <div className="md:hidden">
            <Calendar
              initialFocus
              mode="single"
              defaultMonth={date}
              selected={date}
              onSelect={setDate}
              numberOfMonths={1}
            />
          </div>

          <div className="flex border-t border-t-slate-200">
            <Button
              variant="link"
              className="flex-1 !no-underline !outline-none text-slate-700 text-sm text-center w-full !h-10 hover:bg-slate-50"
              onClick={() => {
                setDate("");
                onClear();
              }}
            >
              Clear filters
            </Button>
            <Popover.Close>
              <Button
                variant="link"
                className="flex-1 !no-underline !outline-none text-slate-700 text-sm text-center w-full !h-10 hover:bg-slate-50"
                onClick={() => {
                  // onApply();
                  setFilters({
                    ...filters,
                    createdAt: date || "",
                  });
                }}
                disabled={!date}
              >
                Apply
              </Button>
            </Popover.Close>
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}
