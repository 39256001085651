import { Popover } from "@radix-ui/themes";
import { ReactComponent as RoundedPlusIcon } from "../../../../assets/icons/rounded-plus.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/check2.svg";
import { Button } from "../../../../@/components/ui/button";

export const sellerStatus = [
  { value: "ST", name: "Standard" },
  { value: "PR", name: "Pro" },
  { value: "MX", name: "Max" },
  { value: "UL", name: "Ultra" },
];

export function PlanTypeSelect({
  value,
  onChange,
}: {
  value?: string;
  onChange: (value: string) => void;
}) {
  const selectedStatus = sellerStatus.find((status) => status.value === value);

  return (
    <>
      <Popover.Root>
        <Popover.Trigger className="w-fit !px-2 !py-2 !rounded-[6px] !h-8">
          <button className="shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)] flex items-center gap-2 border border-dashed border-slate-200 rounded-[6px] bg-white px-[13px] py-[5px]">
            <div className="flex gap-2 items-center">
              <RoundedPlusIcon className="w-4 h-4" />
              <span className="text-xs font-medium leading-[18px] text-slate-900">
                Plan Type
              </span>
            </div>
            <div className="border-l border-l-slate-200 h-4" />
            <div className="bg-slate-50 leading-[18px] lowercase rounded-[6px] py-[2px] px-1.5 text-slate-900 text-xs">
              {value?.length ? (
                <span className="capitalize">{selectedStatus?.name}</span>
              ) : (
                "All Types"
              )}
            </div>
          </button>
        </Popover.Trigger>
        <Popover.Content minWidth="250px" maxWidth="250px" className="!p-0">
          <div className="border-y border-y-slate-200 p-1 flex flex-col justify-start max-h-[16vh] overflow-auto">
            {sellerStatus.map((status) => (
              <Button
                variant="link"
                className="flex px-2 py-1 items-center gap-2 w-full !no-underline justify-start hover:bg-slate-50"
                key={status.name}
                onClick={() => onChange(status.value)}
              >
                <div className="w-4 h-4">
                  {value === status.value ? <CheckIcon /> : null}
                </div>
                {status.name}
              </Button>
            ))}
          </div>
          <Button
            variant="link"
            className="!no-underline text-slate-700 text-sm text-center w-full !h-10 hover:bg-slate-50"
            onClick={() => onChange("")}
          >
            Clear filters
          </Button>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
