"use client";

import { Button } from "../../@/components/ui/button";
import clsx from "clsx";
import { cn } from "../../@/lib/utils";
import { ArrowDown, ArrowUpDown } from "lucide-react";
import { ReactComponent as EmailIcon } from "../../assets/icons/email.svg";
import { ReactComponent as StripeIcon } from "../../assets/icons/stripe.svg";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../@/components/ui/hover-card";

export const SortHeader = (column: any) => () => {
  if (column.getIsSorted() === "desc") {
    column.clearSorting();
    return;
  }

  column.toggleSorting(column.getIsSorted() === "asc");
};

export const SortIcon = (column: any) => {
  if (column.getIsSorted() === "asc") {
    return <ArrowDown className="h-4 w-4 ml-2 rotate-[180deg]" />;
  }
  if (column.getIsSorted() === "desc") {
    return <ArrowDown className="h-4 w-4 ml-2" />;
  }
  return <ArrowUpDown className="h-4 w-4 ml-2" />;
};

export function SubscriptionTitle({ tier }: { tier: string }) {
  return (
    <div className="relative text-center w-fit">
      {tier === "UL" && (
        // have a black background behind the gradient
        <div className="absolute inset-0 bg-slate-900 rounded-[6px] z-0"></div>
      )}
      <div
        className={clsx(
          "px-2 py-1 rounded-[6px] relative text-center w-fit mx-auto",
          cn(
            tier === "ST" && "bg-[#D5EFFA] text-[#139EA7]",
            tier === "PR" && "bg-[#DECAFF] text-[#883EC2]",
            tier === "MX" && "bg-[#FFE5D6] text-[#AA6413]",
            tier === "UL" && "text-gradient"
          )
        )}
      >
        <div className="text-gradient w-fit"></div>
        <span className="z-1 text-[0.75rem] font-[500] flex h-fit">
          {tier === "ST"
            ? "Standard"
            : tier === "PR"
            ? "Pro"
            : tier === "MX"
            ? "Max"
            : tier === "UL"
            ? "Ultra"
            : ""}
        </span>
      </div>
    </div>
  );
}

export function Status({ status }: { status: any }) {
  if (status?.[0]?.status === "active") {
    return (
      <HoverCard>
        <HoverCardTrigger>
          <div className="flex items-center bg-lime-50 w-fit px-1.5 py-[0.1875rem] !rounded-[6px]">
            <span className="text-green-900 font-medium text-xs">
              {status?.[0]?.statusText}
            </span>
          </div>
        </HoverCardTrigger>
        <HoverCardContent side="top" className="text-xs">
          {status?.[0]?.statusDescription}
        </HoverCardContent>
      </HoverCard>
    );
  }
  return (
    <HoverCard>
      <HoverCardTrigger>
        <div className="flex items-center bg-red-50 w-fit px-1.5 py-0.5 !rounded-[6px]">
          <span className="text-red-900 font-medium text-xs">
            {status?.[0]?.statusText}
          </span>
        </div>
      </HoverCardTrigger>
      <HoverCardContent side="top" className="text-xs">
        {status?.[0]?.statusDescription}
      </HoverCardContent>
    </HoverCard>
  );
}

export function Links({ links }: { links: any }) {
  // console.log(links);
  return (
    <div className="flex items-center space-x-1.5 flex-row">
      <HoverCard>
        <HoverCardTrigger>
          <a href={`mailto:${links?.[0]?.email}`}>
            <Button variant="outline" className="p-1.5 h-fit">
              <EmailIcon className="h-4 w-4" />
            </Button>
          </a>
        </HoverCardTrigger>
        <HoverCardContent side="top" className="text-xs">
          <a href={`mailto:${links?.[0]?.email}`}>{links?.[0]?.email}</a>
        </HoverCardContent>
      </HoverCard>

      <HoverCard>
        <HoverCardTrigger>
          <Button
            variant="outline"
            className="p-1.5 h-fit"
            disabled={!links?.[0]?.stripeCustomerId}
          >
            <StripeIcon className="h-4 w-4" />
          </Button>
        </HoverCardTrigger>
        {links?.[0]?.stripeCustomerId && (
          <HoverCardContent side="top" className="text-xs">
            Open{" "}
            <a
              href={`https://dashboard.stripe.com/customers/${links?.[0]?.stripeCustomerId}`}
              target="_blank"
            >
              <span className="underline">{links?.[0]?.stripeCustomerId}</span>{" "}
              in
            </a>
            Stripe
          </HoverCardContent>
        )}
      </HoverCard>
    </div>
  );
}
