import { Input } from "../../../../@/components/ui/input";

export function DollarInput({ className, showDollar = false, ...props }) {
  return (
    <div className="relative">
      {showDollar && <span className="absolute left-1.5 top-1">$</span>}
      <Input className={`h-8 pl-4 ${className || ""}`} {...props} />
    </div>
  );
}
