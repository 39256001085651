import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Sidebar from "./components/Sidebar";
import { UserProvider } from "./module/auth/context/user-context";

function MainApp() {
  return (
    <div className="flex-1 flex flex-col lg:flex-row">
      <Sidebar />
      <div className="flex-1 max-h-screen overflow-auto bg-slate-50 lg:pt-0">
        <Outlet />
      </div>
    </div>
  );
}

export function Main() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login", {
        state: { from: `${location.pathname}${location.search}` },
      });
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
      if (location.pathname === "/") {
        navigate("/dashboard");
      }
    }
  }, [location]);

  if (!isLoggedIn) return null;
  // if (isLoading) return null;

  return (
    <UserProvider>
      <MainApp />
    </UserProvider>
  );
}
