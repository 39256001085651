import { Button } from "../../../../@/components/ui/button";
import { useEffect, useMemo, useState } from "react";
import { Input } from "../../../../@/components/ui/input";
import { api } from "../../../../helpers/api";
import { getUser } from "../../../../helpers/utils";
import { StatusSelect } from "./StatusSelect";
import { Dialog } from "@radix-ui/themes";
import { Cross2Icon } from "@radix-ui/react-icons";
import { TagsSelect } from "./TagsSelect";
import { ErrorView } from "../../../../components/Error";
import { marketPlaceIds } from "../../../product-feed/SellerList/constant";
import { extractSellerIdFromUrl } from "../context/utils";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import Spinner from "../../../../components/Spinners";
import { toast } from "../../../../@/components/ui/use-toast";

type SellerForm = {
  id?: string;
  sellerId?: string;
  nickName?: string;
  status?: string;
  tags?: string[];
};

export function SellerFormModal({
  open,
  setOpen,
  onSuccess,
  seller,
  isActiveSellerReached,
  onSubscription,
  tagList,
  user,
}: {
  open: boolean;
  setOpen: any;
  onSuccess: () => void;
  seller?: any;
  isActiveSellerReached?: boolean;
  onSubscription?: () => void;
  tagList: string[];
  user: any;
}) {
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<SellerForm>({});
  const [saving, setSaving] = useState<boolean>(false);
  const isEdit = !!seller?.id;

  const handleSave = async () => {
    try {
      setSaving(true);
      let payload: any = {};
      if (!form.id) {
        const sellerId = extractSellerIdFromUrl(form.sellerId as string, true);
        payload = {
          ...form,
          sellerId,
        };
      } else {
        payload = {
          id: form.id,
          status: form.status,
          tags: form.tags,
        };
        if (form.nickName !== seller.nickName) {
          payload.nickName = form.nickName;
        }
      }
      const API = form.id ? api.updateSeller : api.addSeller;
      const res = await API(user.id, {
        ...payload,
      });
      setSaving(false);
      if ([200, 201].includes(res.status)) {
        setOpen(false);
        onSuccess();
        toast({
          title: isEdit
            ? "Seller has been updated."
            : "New seller has been added.",
          description: "We’ll keep you updated.",
        });
      } else {
        setErrors(res.data);
      }
    } catch (error: any) {
      console.log(error);
      setSaving(false);
      const message = error?.response?.data?.message;
      if (message) {
        if (message.includes("nick name") || message.includes("Nickname")) {
          setErrors((prev) => ({
            ...prev,
            nickName: message.split(":")[1] || message,
          }));
        } else {
          toast({
            title: message,
            description: "Please try again.",
            action: (
              <Button
                variant="outline"
                onClick={() => setSaving(false)}
                className="!text-slate-900 !bg-white text- hover:!bg-slate-50 border border-slate200 !h-8 !px-3 !py-1"
              >
                Try again
              </Button>
            ),
          });
        }
      }
      setSaving(false);
    }
  };

  const handleSellerIdChange = (e) => {
    const value = e.target.value;
    setForm((prev) => ({
      ...prev,
      sellerId: value,
    }));
    if (marketPlaceIds.find((id) => id === value)) {
      setErrors((prev) => ({
        ...prev,
        sellerId: "This is a marketplace ID",
      }));
    } else {
      const sellerId = extractSellerIdFromUrl(value);
      if (value.startsWith("https://") && !sellerId) {
        setErrors((prev) => ({
          ...prev,
          sellerId: "This does not contain a seller ID",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          sellerId: "",
        }));
      }
    }
  };

  const handleExternalLink = () => {
    // open in new tab
    const link =
      "https://help.stealthseller.co/en/articles/9348725-how-to-get-seller-id";
    window.open(link, "_blank");
  };

  const isValidSellerId = useMemo(() => {
    return (
      form.sellerId &&
      !errors.sellerId &&
      !marketPlaceIds.includes(form.sellerId)
    );
  }, [errors.sellerId, form.sellerId]);

  useEffect(() => {
    if (open && seller) {
      setForm({ ...seller });
    }
  }, [open, seller]);

  const disabled = isEdit ? !form.status : !isValidSellerId || !form.status;

  return (
    <Dialog.Root open={open} onOpenChange={() => setOpen(false)}>
      <Dialog.Content style={{ zIndex: "102" }} maxWidth={"420px"}>
        <div className="font-grotesk text-[1.25rem] font-[500] flex justify-between tracking-normal leading-[120%]">
          <h3>{isEdit ? "Edit seller" : "Add a seller"}</h3>
          <Dialog.Close>
            <Cross2Icon />
          </Dialog.Close>
        </div>
        <div className="grid gap-4 mt-5">
          <div>
            <div className="flex justify-between items-center">
              <label className="text-slate-700 font-medium text-sm mb-1.5">
                Seller ID*
              </label>
              {!isEdit && (
                <Button
                  variant={"link"}
                  className="underline !text-sm !font-medium !pr-0 !outline-none !ring-0"
                  onClick={handleExternalLink}
                >
                  How do I get seller ID?
                </Button>
              )}
            </div>
            <Input
              placeholder="Enter your seller ID"
              value={form.sellerId}
              name="sellerId"
              onChange={handleSellerIdChange}
              className="!h-10 mb-1"
              disabled={isEdit}
            />
            {errors.sellerId ? (
              <ErrorView message={errors.sellerId} />
            ) : form.sellerId &&
              extractSellerIdFromUrl(form.sellerId) !== form.sellerId ? (
              <div className="px-1.5 py-0.5 rounded-md justify-start items-center gap-1 flex">
                <InfoIcon className="w-3 h-3 [&_path]:stroke-[#334155]" />
                <div className="text-slate-700 text-xs font-medium leading-[18px]">
                  Found {extractSellerIdFromUrl(form.sellerId)} in URL
                </div>
              </div>
            ) : null}
          </div>
          <div>
            <div className="flex justify-between items-center mb-1.5">
              <label className="text-slate-700 font-medium text-sm">
                Nickname{" "}
                <span className="text-[#64748B] text-xs">optional</span>
              </label>
            </div>
            <Input
              placeholder="Enter nick name"
              value={form.nickName}
              onChange={(e) => {
                setErrors((prev) => ({ ...prev, nickName: "" }));
                setForm((prev) => ({ ...prev, nickName: e.target.value }));
              }}
              className="!h-10"
            />
            {errors.nickName && <ErrorView message={errors.nickName} />}
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between items-center mb-1.5">
              <label className="text-slate-700 font-medium text-sm">
                Assign status*
              </label>
            </div>
            <StatusSelect
              value={form.status}
              onChange={(value) => {
                if (isActiveSellerReached && value === "A") {
                  setErrors((prev) => ({
                    ...prev,
                    status: "maximum-active-seller-reached",
                  }));
                } else {
                  setErrors((prev) => ({
                    ...prev,
                    status: "",
                  }));
                  setForm((prev) => ({
                    ...prev,
                    status: value,
                  }));
                }
              }}
            />
            {errors.status && (
              <>
                {errors.status === "maximum-active-seller-reached" ? (
                  <ErrorView
                    message={
                      <div>
                        Maximum active sellers reached.{" "}
                        <button
                          onClick={onSubscription}
                          className="text-red-900 text-xs font-medium leading-[18px] underline "
                        >
                          Upgrade here
                        </button>
                      </div>
                    }
                  />
                ) : (
                  <ErrorView message={errors.status} />
                )}
              </>
            )}
          </div>
          <div>
            <div className="flex justify-between items-center mb-1.5">
              <label className="text-slate-700 font-medium text-sm">
                Assign tag(s)
              </label>
            </div>
            <TagsSelect
              value={form.tags}
              onChange={(value) => {
                if (value.length > 5) {
                  setErrors((prev) => ({
                    ...prev,
                    tags: "Maximum tags reached",
                  }));
                } else {
                  setErrors((prev) => ({
                    ...prev,
                    tags: "",
                  }));
                  setForm((prev) => ({
                    ...prev,
                    tags: value,
                  }));
                }
              }}
              error={errors.tags}
              tagList={tagList}
            />
            {errors.tags && <ErrorView message={errors.tags} />}
          </div>
        </div>
        <div className="mt-4">
          <Button
            onClick={handleSave}
            disabled={disabled}
            className="!w-full gap-1.5 !h-10"
          >
            {isEdit ? "Save" : "Add Seller"}
            {saving && <Spinner />}
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
