export const sortingOptions = [
  {
    text: "Active",
    value: "active",
  },
  {
    text: "Paused",
    value: "paused",
  },
  {
    text: "Bookmarked",
    value: "bookmarked",
  },
  {
    text: "Most Saved",
    value: "most_saved",
  },
  {
    text: "New Products",
    value: "new_products",
  },
];

export const statusColors = [
  {
    name: "A",
    bg: "bg-lime-50",
    border: "border-[#14532D]",
    title: "Active",
  },
  {
    name: "P",
    bg: "bg-red-50",
    border: "border-red-900",
    title: "Paused",
  },
  {
    name: "bookmarked",
    bg: "bg-blue-50",
    border: "border-blue-900",
    title: "Bookmarked",
  },
  {
    name: "most_saved",
    bg: "bg-green-50",
    border: "border-green-900",
    title: "Most Saved",
  },
  {
    name: "new_products",
    bg: "bg-purple-50",
    border: "border-purple-900",
    title: "New Products",
  },
];

export const productAttribute = {
  buyBox: {
    bg: "bg-[#FDF2F8]",
    border: "border-[#B73382]",
    name: "buyBox",
    title: "Buy Box",
  },
  salesRank: {
    bg: "bg-[#D9EDF7]",
    border: "border-[#337AB7]",
    name: "salesRank",
    title: "Sales Rank",
  },
  monthlySold: {
    bg: "bg-[#FEFAE9]",
    border: "border-[#FFC42E]",
    name: "monthlySold",
    title: "Monthly Sold",
  },
  afterFees: {
    bg: "bg-[#EADEC8]",
    border: "border-[#7B5C46]",
    name: "afterFees",
    title: "After Fees",
  },
};

export const NumberFilter = {
  GRATER_THAN: "grater_than",
  LESS_THAN: "less_than",
  BETWEEN: "between",
};

export const numberFilterOption = [
  {
    name: NumberFilter.GRATER_THAN,
    text: "Greater than",
  },
  {
    name: NumberFilter.LESS_THAN,
    text: "Less than",
  },
  {
    name: NumberFilter.BETWEEN,
    text: "Between",
  },
];

export const sellerStatus = [
  {
    name: "A",
    title: "Active",
    border: "border-[#14532D]",
    bg: "bg-lime-50",
    textColor: "text-[#14532D]",
    selectBorder: "border-[#14532D]/50",
  },
  {
    name: "P",
    title: "Pause",
    border: "border-[#7F1D1D]",
    bg: "bg-red-50",
    textColor: "text-[#7F1D1D]",
    selectBorder: "border-[#7F1D1D]/50",
  },
];

export const marketPlaceIds = [
  "A2EUQ1WTGCTBG2",
  "ATVPDKIKX0DER",
  "A1AM78C64UM0Y8",
  "A2Q3Y263D00KWC",
  "A1RKKUPIHCS9HS",
  "A1F83G8C2ARO7P",
  "A13V1IB3VIYZZH",
  "AMEN7PMS3EDWL",
  "AMEN7PMS3EDWL",
  "A1805IZSGTT6HS",
  "A1PA6795UKMFR9",
  "APJ6JRA9NG5V4",
  "A2NODRKZP88ZB9",
  "AE08WJ6YKNBMC",
  "A1C3SOZRARQ6R3",
  "ARBP9OOSHTCHU",
  "A33AVAJ2PDY3EV",
  "A17E79C6D8DWNP",
  "A2VIGQ35RCS4UG",
  "A21TJRUUN4KGV",
  "A19VAU5U5O7RUS",
  "A39IBJ37TRP1C6",
  "A1VC38T7YXB528",
];
