import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../@/components/ui/table";
import { useEffect, useMemo, useRef, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import moment from "moment";

type User = {
  id: string;
  name: string;
  email: string;
  joinedOn: string;
  plan: string;
  totalSellers: number;
  status: string;
  statusText: string;
  statusDescription: string;
  totalSpend: string;
  stripeId: string;
  openDrawer: (row: any) => void;
  deleteModal: (row: any) => void;
  deactivateModal: (row: any) => void;
};

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="bg-white [&_td]:py-3 [&_td_div]:rounded-[6px] [&_td]:border-b [&_td]border-b-[#E5E7EB]"
        >
          <td className="pl-3">
            <div className="w-16 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-40 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-40 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-40 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td className="subscription">
            <div className="w-16 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td className="subscription">
            <div className="w-20 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td className="flex items-center gap-1">
            <div className="w-20 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-5 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

export function DataTable({
  columns,
  search,
  filters,
  showActive = true,
  name = "users",
  onRefetchLoad,
  onRowClick,
  sortBy,
  sortOrder,
}: {
  columns: any;
  search: string;
  filters: any;
  showActive?: boolean;
  name?: string;
  onRefetchLoad: any;
  onRowClick?: any;
  sortBy?: "name" | "plan" | "joinedOn" | null;
  sortOrder?: "ASC" | "DESC" | null;
}) {
  const [tableData, setTableData] = useState<User[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const loadMoreRef = useRef<any>(null);

  const fetchUsers = async ({ pageParam = 0 }) => {
    const res = await api.getUsers({
      page: pageParam,
      limit: 10,
      searchKeyword: search,
      type: "CUSTOMERS",
      tiers: filters.tiers,
      showActive,
      createdAt: filters.createdAt
        ? moment(filters.createdAt).format("YYYY-MM-DD")
        : null,
      sortBy,
      sortOrder,
    });

    return res;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery(
    [name, search, filters, sortBy, sortOrder],
    ({ pageParam }) => fetchUsers({ pageParam }),
    {
      getNextPageParam: (lastPage, pages) => {
        // Determine if there are more pages to fetch
        if (lastPage.length < 10) return null;
        return pages.length;
      },
    }
  );

  useEffect(() => {
    onRefetchLoad(refetch);
  }, [refetch]);

  useMemo(() => {
    if (data) {
      // Combine pages into one array
      const newData = data.pages.flatMap((page) => page);
      // Set the combined data into the table data state
      setTableData(newData);
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const table = useReactTable({
    data: tableData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    // getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <div className="flex flex-col rounded-md border overflow-auto lg:h-[calc(100dvh-140px)] h-[60vh] [&>div:first-child]:lg:h-[calc(100dvh-140px)] [&>div:first-child]:h-[60vh] [&>div]:bg-white">
      <Table className="bg-white table">
        <TableHeader className="[&_th]:px-3">
          {table?.getHeaderGroups()?.map((headerGroup) => (
            <TableRow
              className="sticky top-0 z-[1] bg-white"
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead className="bg-white" key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className="[&_td]:px-3">
          {table?.getRowModel()?.rows?.length > 0 &&
            !isLoading &&
            table?.getRowModel()?.rows?.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                onClick={() => onRowClick && onRowClick(row.original)}
                className="cursor-pointer"
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          {table?.getRowModel()?.rows?.length === 0 && !isLoading && (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {isLoading && <LoadingSkeleton />}
      </Table>
      {hasNextPage && (
        <div className="h-5">
          <button
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            ref={loadMoreRef}
          ></button>
        </div>
      )}
    </div>
  );
}
