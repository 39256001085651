import { useQuery } from "@tanstack/react-query";
import { api } from "../../../../helpers/api";
import React from "react";

function GraphView({ productId, userId }) {
  // use query to get products for selected seller
  const { data, isLoading } = useQuery(
    ["product-graph", productId, userId],
    () => api.getGraph(userId, productId),
    {
      enabled: !!productId && !!userId,
    }
  );
  if (!data?.data) return null;

  var urlCreator = window.URL || window.webkitURL;
  var imageUrl = urlCreator.createObjectURL(data?.data);
  return (
    <img src={imageUrl} className="w-full h-full object-contain" alt="chart" />
  );
}

export const Graph = React.memo(GraphView, (prevProps, nextProps) => {
  return (
    prevProps.productId === nextProps.productId &&
    prevProps.userId === nextProps.userId
  );
});
