import { BookmarkButton } from "../../SellerList/components/BookmarkButton";
import { ReactComponent as SellersIcon } from "../../../../assets/icons/sellers.svg";
import { ReactComponent as StarIcon } from "../../../../assets/icons/star.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import { ReactComponent as AmazonIcon } from "../../../../assets/icons/amazon.svg";
import { ReactComponent as GoogleIcon } from "../../../../assets/icons/google-black.svg";
import { ReactComponent as ShoppingIcon } from "../../../../assets/icons/shopping.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy.svg";
import { Button } from "../../../../@/components/ui/button";
import { ColorBoxIcon } from "../ColorBoxIcon";
import { productAttribute } from "../../SellerList/constant";
import { ProductImageViewer } from "./ProductImageViewer";
import { commaSeparatedNumber, formatNumber } from "../../../../helpers/utils";
import { Graph } from "./Graph";
import moment from "moment";
import { Tooltip } from "@radix-ui/themes";
import { useToast } from "../../../../@/components/ui/use-toast";

function MobileProductFeedCard({
  product,
  isSaved,
  onSaveProduct,
  selectedSeller,
}) {
  return (
    <div className="p-3 group flex lg:hidden gap-2 rounded-md border border-slate-200 bg-white">
      <div className="flex-1 flex flex-col gap-1">
        <div className="flex gap-2 mb-1">
          <div className="flex flex-col gap-2">
            <div className="flex gap-1">
              {/* <div className="[&_.bookmark-filled-icon_path]:fill-slate-400 [&_.bookmark-filled-icon_path]:stroke-slate-400">
                <BookmarkButton
                  bookmarked={!!isSaved}
                  onClick={onSaveProduct ? onSaveProduct : () => {}}
                />
              </div> */}
              {!product?.isRead && (
                <div className="rounded-[6px] text-center flex flex-col justify-center w-[38px] h-6 bg-lime-50 border border-[rgba(20,83,45,0.50)] text-green-900 text-xs font-medium">
                  New
                </div>
              )}
            </div>
            <div>
              <ProductImageViewer images={product?.images || []}>
                <div className="w-[87px] h-[87px] cursor-pointer">
                  <img
                    src={product.images[0]}
                    alt="oreo"
                    className="w-full h-full object-contain"
                  />
                </div>
              </ProductImageViewer>
            </div>
          </div>
          <div className="flex-1 relative flex flex-col gap-1">
            <ActionView
              amazonUrl={product?.amazonUrl}
              googleUrl={product?.googleUrl}
              shopUrl={product?.shopUrl}
            />
            <div className="text-slate900 text-sm font-medium line-clamp-2">
              {product?.title}
            </div>
            <div className="flex items-center gap-1">
              <div className="w-fit px-1.5 py-[2px] flex items-center gap-1 bg-slate-50 border rounded-[6px] border-slate-200">
                <span className="text-xs text-slate900 font-medium">
                  {product.category}
                </span>
              </div>
              <div className="flex items-center gap-1">
                <StarIcon />
                <span className="text-slate-400 text-xs font-medium">
                  {(product?.rating || 0) / 10}
                </span>
              </div>
            </div>
            <div>
              <div className="w-fit px-1.5 py-[2px] flex items-center gap-1 bg-slate-50 border rounded-[6px] border-slate-200">
                <span className="text-xs text-slate900 font-medium">
                  {product.productId}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-1">
              <Button
                variant={"outline"}
                color="gray"
                onClick={() => {}}
                className={`px-1.5 h-6 flex gap-1 items-center rounded-[6px] bg-slate-50 border border-slate200`}
              >
                <SellersIcon className="w-3 h-3 [&_path]:stroke-slate-400" />

                <span className="text-slate-400 text-xs font-medium">
                  {product?.sellerName || selectedSeller?.nickName}
                </span>
              </Button>

              <Tooltip
                content={moment(product.timePosted).format("MMM DD, YYYY")}
              >
                <div className="w-fit flex gap-1 items-center px-1.5 py-[2px] bg-slate-50 border border-slate-200 rounded-[6px] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]">
                  <span className="text-slate-400 text-xs font-medium">
                    {moment(product.timePosted).fromNow()}
                  </span>
                  <InfoIcon />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="border-b border-b-[#E2E8F0] my-4" />
        <div className="flex flex-col gap-3">
          <div className="flex items-center flex-wrap gap-[18px]">
            <div className="flex flex-col items-center gap-1">
              <ProductAttributeLabel>Storefront</ProductAttributeLabel>
              <ProductAttribute
                bg={"bg-lime-50"}
                border="border-[#14532D]"
                value={
                  product?.storefrontPrice <= -1
                    ? "NBB"
                    : formatNumber(Number(product?.storefrontPrice || 0) / 100)
                }
                tooltip={"Storefront Price"}
              />
            </div>
            <div className="flex flex-col items-center gap-1">
              <ProductAttributeLabel>Buy Box</ProductAttributeLabel>
              <ProductAttribute
                bg={
                  product?.isBuyBoxFBA
                    ? "bg-[#E6F6D7]"
                    : product?.buyBoxIsAmazon
                    ? "bg-[#FEFAE9]"
                    : productAttribute.buyBox.bg
                }
                border={
                  product?.isBuyBoxFBA
                    ? "border-[#008001]"
                    : product?.buyBoxIsAmazon
                    ? "border-[#8A6D3A]"
                    : productAttribute.buyBox.border
                }
                value={
                  product?.buyBoxPrice === -1
                    ? "NBB"
                    : formatNumber(Number(product?.buyBoxPrice || 0) / 100)
                }
              />
            </div>
            {/* <div className="flex flex-col items-center gap-1">
            <ProductAttributeLabel>After Fees</ProductAttributeLabel>
            <ProductAttribute
              bg={productAttribute.afterFees.bg}
              border={productAttribute.afterFees.border}
              value={formatNumber(product?.afterFeesPrice)}
            />
          </div> */}
            <div className="flex flex-col items-center gap-1">
              <ProductAttributeLabel>
                <div className="flex gap-1 items-center">
                  Offers
                  <ColorBoxIcon bg="bg-[#FDF5EA]" border="border-[#FA8700]" />
                </div>
              </ProductAttributeLabel>
              <div className="flex items-center gap-1">
                <ProductAttribute
                  bg={"bg-[#E6F6D7]"}
                  border="border-[#008001]"
                  value={product?.offerFBACount || 0}
                  tooltip="FBA Count"
                />
                <ProductAttribute
                  bg={"bg-[#FEFAE9]"}
                  border="border-[#8A6D3A]"
                  value={product?.offerFBMCount || 0}
                  tooltip="FBM Count"
                />

                {product?.isOfferAmazon && (
                  <div className="w-fit px-1.5 py-[2px] flex items-center gap-1 bg-[#FDF5EA] border rounded-[6px] border-[#EDC2C2]">
                    <span className={`text-xs text-[#FF8A00] font-medium`}>
                      AMZ
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap gap-[18px]">
            <div className="flex flex-col items-center gap-1">
              <ProductAttributeLabel>
                {productAttribute.salesRank.title}
              </ProductAttributeLabel>
              <ProductAttribute
                bg={productAttribute.salesRank.bg}
                border={productAttribute.salesRank.border}
                value={`${formatNumber(product?.salesRank || 0)} (${
                  product?.salesRankPercentile
                }%)`}
                tooltip="BSR"
              />
            </div>

            <div className="flex flex-col items-center gap-1">
              <ProductAttributeLabel>
                {productAttribute.monthlySold.title}
              </ProductAttributeLabel>
              <ProductAttribute
                bg={productAttribute.monthlySold.bg}
                border={productAttribute.monthlySold.border}
                value={
                  product?.monthlySales
                    ? commaSeparatedNumber(product?.monthlySales || 0)
                    : "<50"
                }
              />
            </div>

            <div className="flex flex-col items-center gap-1">
              <ProductAttributeLabel>Avg Price</ProductAttributeLabel>
              <ProductAttribute
                value={`$${formatNumber(Number(product?.avgPrice || 0) / 100)}`}
              />
            </div>
            <div className="flex flex-col items-center gap-1">
              <ProductAttributeLabel>Stock Count</ProductAttributeLabel>
              <ProductAttribute
                value={product?.stockCount}
                tooltip="Storefront's Stock"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ActionView({ amazonUrl, googleUrl, shopUrl }) {
  return (
    <div className="absolute right-0 top-1 hidden group-hover:flex gap-1">
      <a href={amazonUrl} target="_blank" rel="noreferrer">
        <Button
          variant={"outline"}
          color="gray"
          className="!w-7 !h-7 flex gap-1 items-center px-1.5 py-[2px] bg-slate-50 border border-slate-200 rounded-[6px] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]"
        >
          <AmazonIcon />
        </Button>
      </a>
      <a href={googleUrl} target="_blank" rel="noreferrer">
        <Button
          variant={"outline"}
          color="gray"
          className="!w-7 !h-7 flex gap-1 items-center px-1.5 py-[2px] bg-slate-50 border border-slate-200 rounded-[6px] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]"
        >
          <GoogleIcon />
        </Button>
      </a>
      <a href={shopUrl} target="_blank" rel="noreferrer">
        <Button
          variant={"outline"}
          color="gray"
          className="!w-7 !h-7 flex gap-1 items-center px-1.5 py-[2px] bg-slate-50 border border-slate-200 rounded-[6px] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]"
        >
          <ShoppingIcon />
        </Button>
      </a>
    </div>
  );
}

function ProductAttributeLabel({ children }) {
  return (
    <div className="text-slate-500 text-[11px] font-normal">{children}</div>
  );
}

function ProductAttribute({
  bg,
  border,
  value,
  text,
  tooltip,
}: {
  bg?: string;
  border?: string;
  value: string;
  text?: string;
  tooltip?: string;
}) {
  // console.log("tooltip", tooltip);
  const renderAttribute = () => {
    return (
      <div className="w-fit px-1.5 py-[2px] flex items-center gap-1 bg-slate-50 border rounded-[6px] border-slate-200">
        {bg && <ColorBoxIcon bg={bg} border={border} />}
        <span className={`text-xs ${text || "text-slate900"} font-medium`}>
          {value}
        </span>
      </div>
    );
  };
  if (tooltip) {
    return <Tooltip content={tooltip}>{renderAttribute()}</Tooltip>;
  }
  return <>{renderAttribute()}</>;
}

export function ProductFeedCard({
  product,
  selectedSeller,
  isSaved,
  onSaveProduct,
}: {
  product: any;
  selectedSeller: any;
  isSaved?: boolean;
  onSaveProduct?: () => void;
}) {
  const { toast } = useToast();

  return (
    <>
      <div className="p-3 group hidden lg:flex gap-2 rounded-md border border-slate-200 bg-white">
        <div className="flex-1 flex flex-col gap-1">
          <div className="flex gap-2 mb-1">
            <div className="flex flex-col gap-2">
              <div className="flex gap-1">
                {/* <div className="[&_.bookmark-filled-icon_path]:fill-slate-900 [&_.bookmark-filled-icon_path]:stroke-slate-900">
                  <BookmarkButton
                    bookmarked={!!isSaved}
                    onClick={onSaveProduct ? onSaveProduct : () => {}}
                  />
                </div> */}
                {!product?.isRead && (
                  <div className="rounded-[6px] text-center flex flex-col justify-center w-[38px] h-6 bg-slate-900 text-white text-xs font-medium">
                    New
                  </div>
                )}
              </div>
              <div>
                <ProductImageViewer images={product?.images || []}>
                  <div className="w-[87px] h-[87px] cursor-pointer">
                    <img
                      src={product.images[0]}
                      alt="oreo"
                      className="w-full h-full object-contain"
                    />
                  </div>
                </ProductImageViewer>
              </div>
            </div>
            <div className="flex-1 relative flex flex-col gap-1">
              <ActionView
                amazonUrl={product?.amazonUrl}
                googleUrl={product?.googleUrl}
                shopUrl={product?.shopUrl}
              />
              <a
                href={product?.amazonUrl}
                target="_blank"
                rel="noreferrer"
                className="text-slate900 text-sm font-medium hover:underline cursor-pointer pr-4"
              >
                {product?.title}
              </a>
              <div className="flex items-center gap-1">
                <div className="w-fit px-1.5 py-[2px] flex items-center gap-1 bg-slate-50 border rounded-[6px] border-slate-200">
                  <span className="text-xs text-slate900 font-medium">
                    {product.category}
                  </span>
                </div>
                <div className="flex items-center gap-1">
                  <StarIcon />
                  <span className="text-slate-400 text-xs font-medium">
                    {(product?.rating || 0) / 10}
                  </span>
                </div>
              </div>
              <div>
                <div className="w-fit px-1.5 py-[2px] flex items-center gap-1 bg-slate-50 border rounded-[6px] border-slate-200">
                  <div className="flex flex-row gap-4">
                    <Tooltip
                      content={
                        <button
                          className="flex flex-row gap-1"
                          onClick={() =>
                            navigator.clipboard
                              .writeText(product.productId)
                              .then(
                                () => {
                                  toast({
                                    description: `Product ID: ${product.productId} copied to clipboard`,
                                  });
                                },
                                () => {
                                  toast({
                                    description: "Failed to copy product ID",
                                    variant: "destructive",
                                  });
                                }
                              )
                          }
                        >
                          <span className="text-xs text-white font-medium">
                            {product.productId}
                          </span>
                          <CopyIcon />
                        </button>
                      }
                    >
                      <span className="text-xs text-slate-900 font-medium">
                        {product.productId}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* <div>
                <Button
                  variant={"outline"}
                  color="gray"
                  onClick={() => {}}
                  className={`px-1.5 h-6 flex gap-1 items-center rounded-[6px] bg-slate-50 border border-slate200`}
                >
                  <SellersIcon className="w-3 h-3 [&_path]:stroke-slate-400" />

                  <span className="text-slate-400 text-xs font-medium">
                    {product?.sellerName || selectedSeller?.nickName}
                  </span>
                </Button>
              </div> */}
            </div>
          </div>
          <div className="flex items-center flex-wrap gap-1">
            <div className="flex flex-col items-center gap-1">
              <ProductAttributeLabel>Storefront</ProductAttributeLabel>
              <ProductAttribute
                bg={"bg-lime-50"}
                border="border-[#14532D]"
                value={
                  product?.storefrontPrice <= -1
                    ? "NBB"
                    : "$" +
                      formatNumber(Number(product?.storefrontPrice || 0) / 100)
                }
                tooltip={"Storefront Price"}
              />
            </div>
            <div className="flex flex-col items-center gap-1">
              <ProductAttributeLabel>Buy Box</ProductAttributeLabel>
              <ProductAttribute
                bg={
                  product?.isBuyBoxFBA
                    ? "bg-[#E6F6D7]"
                    : product?.buyBoxIsAmazon
                    ? "bg-[#FEFAE9]"
                    : productAttribute.buyBox.bg
                }
                border={
                  product?.isBuyBoxFBA
                    ? "border-[#008001]"
                    : product?.buyBoxIsAmazon
                    ? "border-[#8A6D3A]"
                    : productAttribute.buyBox.border
                }
                value={
                  product?.buyBoxPrice === -1
                    ? "NBB"
                    : "$" +
                      formatNumber(Number(product?.buyBoxPrice || 0) / 100)
                }
              />
            </div>
            {/* <div className="flex flex-col items-center gap-1">
            <ProductAttributeLabel>After Fees</ProductAttributeLabel>
            <ProductAttribute
              bg={productAttribute.afterFees.bg}
              border={productAttribute.afterFees.border}
              value={formatNumber(product?.afterFeesPrice)}
            />
          </div> */}
            <div className="flex flex-col items-center gap-1">
              <ProductAttributeLabel>
                <div className="flex gap-1 items-center">
                  Offers
                  {/* <ColorBoxIcon bg="bg-[#FDF5EA]" border="border-[#FA8700]" /> */}
                </div>
              </ProductAttributeLabel>
              <div className="flex items-center gap-1">
                <ProductAttribute
                  bg={"bg-[#E6F6D7]"}
                  border="border-[#008001]"
                  value={product?.offerFBACount || 0}
                  tooltip="FBA Count"
                />
                <ProductAttribute
                  bg={"bg-[#FEFAE9]"}
                  border="border-[#8A6D3A]"
                  value={product?.offerFBMCount || 0}
                  tooltip="FBM Count"
                />

                {product?.isOfferAmazon && (
                  <div className="w-fit px-1.5 py-[2px] flex items-center gap-1 bg-[#FDF5EA] border rounded-[6px] border-[#EDC2C2]">
                    <span className={`text-xs text-[#FF8A00] font-medium`}>
                      AMZ
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col items-center gap-1">
              <ProductAttributeLabel>
                {productAttribute.salesRank.title}
              </ProductAttributeLabel>
              <ProductAttribute
                bg={productAttribute.salesRank.bg}
                border={productAttribute.salesRank.border}
                value={`${formatNumber(product?.salesRank || 0, 0)} (${
                  product?.salesRankPercentile
                }%)`}
                tooltip="BSR"
              />
            </div>

            <div className="flex flex-col items-center gap-1">
              <ProductAttributeLabel>
                {productAttribute.monthlySold.title}
              </ProductAttributeLabel>
              <ProductAttribute
                bg={productAttribute.monthlySold.bg}
                border={productAttribute.monthlySold.border}
                value={
                  product?.monthlySales
                    ? commaSeparatedNumber(product?.monthlySales || 0)
                    : "<50"
                }
              />
            </div>

            <div className="flex flex-col items-center gap-1">
              <ProductAttributeLabel>Avg Price</ProductAttributeLabel>
              <ProductAttribute
                value={`$${formatNumber(Number(product?.avgPrice || 0) / 100)}`}
              />
            </div>
            <div className="flex flex-col items-center gap-1">
              <ProductAttributeLabel>Stock Count</ProductAttributeLabel>
              <ProductAttribute
                value={product?.stockCount}
                tooltip="Storefront's Stock"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between items-end">
          <div className="w-[350px] h-auto">
            {/* <img
            src="/images/chart.png"
            className="w-full h-full object-contain"
            alt="chart"
          /> */}
            <Graph productId={product?.id} userId={product?.sellerId} />
          </div>
          <div>
            <Tooltip
              content={moment(product.timePosted).format("MMM DD, YYYY")}
            >
              <div className="w-fit flex gap-1 items-center px-1.5 py-[2px] !bg-[white] border border-slate-200 rounded-[6px]">
                <span className="text-black text-xs font-medium">
                  {moment(product.timePosted).fromNow()}
                </span>
                <InfoIcon className="[&_path]:stroke-black" />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <MobileProductFeedCard
        product={product}
        selectedSeller={selectedSeller}
        isSaved={isSaved}
        onSaveProduct={onSaveProduct}
      />
    </>
  );
}
