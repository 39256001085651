import { Button } from "../../@/components/ui/button";
import { ReactComponent as DollarIcon } from "../../assets/icons/dollar.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg";
import { ReactComponent as SellerIcon } from "../../assets/icons/sellers.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/email.svg";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../@/components/ui/select";
import {
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  AreaChart,
  XAxis,
  YAxis,
  Area,
} from "recharts";
import { useNavigate } from "react-router-dom";
import { SubscriptionTitle } from "../users/columns";
import { useState } from "react";
import { ArrowUp } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import { formatNumber, getUser } from "../../helpers/utils";
import { USER_TYPES } from "../../helpers/consts";
import moment from "moment";

const FilterOptions = {
  CURRENT_WEEK: "This week",
  CURRENT_MONTH: "This month",
  CURRENT_YEAR: "This year",
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function CustomizedLabel({ x, y, payload, ...rest }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={6} y={0} dy={16} textAnchor="end" fill="#666">
        {rest.type.type === "CURRENT_WEEK" &&
          moment(payload.value).format("ddd")}
        {rest.type.type === "CURRENT_MONTH" &&
          moment(payload.value).format("DD")}
        {rest.type.type === "CURRENT_YEAR" &&
          (payload.value >= 1 && payload.value <= 12
            ? months[payload.value - 1]
            : moment(payload.value).format("MMM DD, YYYY"))}

        {/* {rest.type === "CURRENT_WEEK"
          ? moment(payload.value).format("dd")
          : rest.type === "CURRENT_MONTH"
          ? moment(payload.value).format("DD")
          : // turn month number to month name
          payload.value >= 1 && payload.value <= 12
          ? months[payload.value - 1]
          : moment(payload.value).format("MMM DD, YYYY")} */}
      </text>
    </g>
  );
}
const CustomTooltip = ({ active, payload, label }: any) => {
  console.log(payload?.[0]?.payload);
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col gap-2 min-w-[160px] items-center justify-center p-[0.875rem] !shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10),0px_1px_2px_-1px_rgba(0,0,0,0.10)] rounded-[11px] bg-white">
        <p className="leading-[113.6%] font-[400] text-sm font-inter opacity-50 !text-black">
          {typeof payload[0]?.payload?.name === "string"
            ? moment(payload[0]?.payload?.name).format("MMM DD, YYYY")
            : moment().month(payload[0]?.payload?.name).format("MMMM")}
        </p>
        <p className="text-[1rem] font-bold leading-[113.6%]">
          {payload[0].value}
        </p>
      </div>
    );
  }

  return null;
};

export default function Dashboard() {
  const user = getUser();
  const { data, refetch, isLoading } = useQuery(
    ["dashboard", user?.id],
    () => api.fetchDashboard(user.id),
    {
      enabled: !!user?.id,
    }
  );
  console.log(data, "dashboard data");
  return (
    <div>
      <div className="border-slate-300 w-full border-b-solid border-b-[1px] py-[0.875rem] px-[0.9375rem] h-[3.25rem] lg:flex hidden">
        <h1 className="text-[1.125rem] font-[500]">Dashboard</h1>
      </div>
      <div className="flex flex-col p-6 gap-6">
        <div className="flex flex-row flex-wrap w-full gap-5">
          <Card
            title="Total revenue"
            value={`$${formatNumber(Number(data?.totalRevenue))}`}
            icon={<DollarIcon />}
          />
          <Card
            title="Total users"
            value={formatNumber(Number(data?.totalUsers), 0)}
            icon={<UserIcon />}
          />
          <Card
            title="Total active sellers "
            value={formatNumber(Number(data?.totalSellers), 0)}
            icon={<SellerIcon />}
          />
        </div>
        <div className="flex flex-row gap-5 flex-wrap">
          <Users />
          <RecentSubscribers />
        </div>
      </div>
    </div>
  );
}

function Card({
  title,
  value,
  icon,
}: {
  title: string;
  value: string;
  icon: React.ReactNode;
}) {
  return (
    <div className="bg-white shadow-customCard p-4 rounded-[12px] flex flex-row justify-between border-slate-200 border-solid border-[1px] flex-1">
      <div className="grid gap-[0.8125rem]">
        <h2 className="text-[#374151] text-[0.875rem] leading-[120%]">
          {title}
        </h2>
        <h3 className="text-[1.125rem] font-[500] leading-[120%] font-grotesk">
          {value}
        </h3>
      </div>
      <Button variant="secondary" className="p-[0.625rem]">
        {icon}
      </Button>
    </div>
  );
}

function RecentSubscribers() {
  const navigate = useNavigate();
  const user = getUser();
  const { data, refetch, isLoading } = useQuery(
    ["subscribers"],
    () => api.getUsers({ page: 0, limit: 10, type: USER_TYPES.SUBSCRIBERS }),
    {
      enabled: !!user?.id,
    }
  );
  // console.log(data, "subscribers data");
  return (
    <div className="justify-start bg-white shadow-customCard pb-0 p-6 rounded-[12px] flex flex-col gap-4 border-slate-200 border-solid border-[1px] flex-1">
      <div className="flex flex-row items-center justify-between w-full h-fit mb-2">
        <h3 className="text-[1.125rem] font-[500] font-grotesk">
          Recent subscribers
        </h3>
        <Button
          variant="link"
          className="text-[#374151] underline p-0 m-0 h-fit"
          onClick={() => {
            navigate("/users");
          }}
        >
          View all users
        </Button>
      </div>
      <div className="h-[calc(100vh-300px)] overflow-auto gap-4 flex flex-col">
        {data?.map((subscriber) => (
          <Subscriber key={subscriber.id} subscriber={subscriber} />
        ))}
      </div>
    </div>
  );
}

function Subscriber({ subscriber }) {
  return (
    <div className="w-full p-4 border border-slate-200 rounded-[6px] flex flex-row justify-between">
      <div className="flex flex-col gap-2">
        <span className="text-slate-800 text-[1rem] font-[600] leading-[120%]">
          {subscriber?.fullname}
        </span>
        <div className="flex gap-[0.375rem] items-center">
          <EmailIcon className="[&_path]:stroke-slate-500" />
          <span className="text-slate-500 font-medium">
            {subscriber?.email}
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-2 items-end">
        <div className="flex flex-row gap-2 items-center">
          <span className="text-xs text-slate-500">${subscriber.amount}</span>
          <SubscriptionTitle tier={subscriber.tier} />
        </div>
        <span className="text-slate-500 font-medium text-xs">
          Subscribed on{" "}
          {moment(subscriber?.subscribedon).format("MMM DD, YYYY")}
        </span>
      </div>
    </div>
  );
}

function Users() {
  const user = getUser();
  const [selectedChart, setSelectedChart] = useState<{
    type: string;
    startDate?: string;
    endDate?: string;
  }>({ type: "CURRENT_WEEK" });

  const { data: chartData } = useQuery({
    queryKey: ["dashboard-charts", selectedChart, user.id],
    queryFn: () =>
      api.getDashboardCharts({
        duration: selectedChart.type,
        startDate: selectedChart.startDate,
        endDate: selectedChart.endDate,
        id: user.id,
        type: "USERS_COUNT",
      }),
  });
  // console.log(chartData, "chart data");
  return (
    <div className="bg-white overflow-auto shadow-customCard px-6 py-5 rounded-[12px] flex flex-col justify-between border-slate-200 border-solid border-[1px] flex-1 h-[calc(100vh-220px)]">
      <div className="flex flex-row items-center justify-between w-full h-fit">
        <h3 className="text-[1.125rem] font-[500] font-grotesk">Users</h3>

        <Select
          onValueChange={(value) => {
            setSelectedChart({
              type: value,
            });
          }}
        >
          <SelectTrigger className="w-fit p-0 bg-slate-100 !border-none focus:ring-transparent">
            <button className="py-2 pl-3 pr-1 h-fit text-[1rem] text-black focus:ring-transparent">
              {FilterOptions[selectedChart.type]}
            </button>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup className="font-inter font-medium">
              <SelectItem value="CURRENT_WEEK">This week</SelectItem>
              <SelectItem value="CURRENT_MONTH">This month</SelectItem>
              <SelectItem value="CURRENT_YEAR">This year</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      <div className="border border-[#E8E8E8] rounded-[8px] p-4 mt-6 mb-8 flex flex-row justify-between">
        <span className="text-black">Mar 23 - Mar 29</span>
        <div className="flex flex-row gap-2 items-center">
          <span className="font-bold">
            {(chartData?.users || []).reduce(
              (acc, item) => acc + item.count,
              0
            )}
          </span>
          <div className="rounded-full p-1.5 bg-lime-50">
            <ArrowUp className="h-4 w-4 [&_path]:stroke-green-900" />
          </div>
        </div>
      </div>

      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={
            (chartData?.users || []).map((item) => ({
              name: item.day || item.month,
              count: item.count,
            })) || []
          }
          className="!cursor-pointer"
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient
              id="paint0_linear_53_758"
              x1="0"
              y1="0" // Start from the bottom (100%)
              x2="0"
              y2="100%" // End at the top (0)
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="rgba(0, 0, 0, 0.15)" />
              <stop offset="1" stop-color="rgba(0, 0, 0, 0)" stop-opacity="1" />
            </linearGradient>
          </defs>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="name"
            tick={(props) => (
              <CustomizedLabel {...props} type={selectedChart} />
            )}
          />
          <YAxis
          // tickFormatter={(tick) => {
          //   return Number.isInteger(tick) ? tick : "";
          // }}
          // tickSize={0}
          // domain={[
          //   (dataMin) => 0 - Math.abs(dataMin),
          //   (dataMax) => dataMax * 2,
          // ]}
          />
          {/* <Tooltip content={renderUsersTooltip} /> */}
          {/* <Tooltip /> */}
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="count"
            stroke="#000"
            strokeWidth="1px"
            fill="url(#paint0_linear_53_758)"
            className="!cursor-pointer"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
