import { Select } from "@radix-ui/themes";

export function SelectInput({ list, label, value, onChange, className = "" }) {
  return (
    <Select.Root value={value} onValueChange={onChange}>
      <Select.Trigger
        className={`trigger !px-3 !py-1 !rounded-[6px] !min-w-[135px] !shadow-[inset_0_0_0_1px_#E2E8F0] ${className}`}
      >
        <div className="custom-label mr-1.5">{label}</div>
      </Select.Trigger>
      <Select.Content style={{ zIndex: "101" }} position="popper">
        {list.map((item) => (
          <Select.Item
            key={item.value}
            value={item.value}
            className={`hover:!text-slate700 hover:!bg-[#F1F5F9] focus:!bg-slate-50 focus:!text-slate700 ${
              item.value === value ? "!bg-slate-50 !text-slate700" : ""
            } !rounded-[6px] !cursor-pointer`}
          >
            {item.text}
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
}
