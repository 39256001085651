import { Chip } from "../../../product-feed/components/Chip";
import { FavoriteTag, favoriteTagInfo } from "./FavoriteTag";
import { tagsToSlice } from "./TagsSelect";

export const RenderTags = ({
  tags = [],
  visibleCount,
}: {
  tags: string[];
  visibleCount?: number;
}) => {
  if (!tags || tags.length === 0) return null;

  const numberOfTags = visibleCount || tagsToSlice(tags);
  const remainingTags = tags.length - numberOfTags;

  return (
    <>
      {tags
        ?.slice(0, numberOfTags)
        ?.map((tag, i) =>
          tag.toLowerCase() === favoriteTagInfo.name.toLowerCase() ? (
            <FavoriteTag key={i} />
          ) : (
            <Chip key={tag}>{tag}</Chip>
          )
        )}
      {remainingTags > 0 && <Chip>+{remainingTags}</Chip>}
    </>
  );
};
