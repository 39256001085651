import { Button } from "../../@/components/ui/button";
import { DataTable } from "./data-table";
import { useRef, useState } from "react";
import Modal from "../../components/Modal";
import { ColumnDef } from "@tanstack/react-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../@/components/ui/dropdown-menu";
import { ReactComponent as MoreVertical } from "../../assets/icons/more-vertical.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import moment from "moment";
import {
  Links,
  SortHeader,
  SortIcon,
  Status,
  SubscriptionTitle,
} from "./columns";
import { UserDetails } from "./UserDetails";
import { useDebounce } from "../../helpers/useDebounce";
import { UserFilterView } from "./components/components/UserFilterView";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "../../@/components/ui/drawer";
import { api } from "../../helpers/api";
import { useToast } from "../../@/components/ui/use-toast";
import hotToast from "react-hot-toast";
import Spinner from "../../components/Spinners";
import { subscriptionList } from "../../helpers/consts";
import { getTotalSellers } from "./Users";

type User = {
  id: string;
  name: string;
  email: string;
  joinedOn: string;
  tier: string;
  totalSellers: number;
  status: string;
  statusText: string;
  statusDescription: string;
  totalSpend: string;
  stripeCustomerId: string;
  subscription: any;
  openDrawer: (row: any) => void;
  deleteModal: (row: any) => void;
  deactivateModal: (row: any) => void;
};

export default function BlockedUsers({ open, onClose }) {
  const [deleteModal, setDeleteModal] = useState<any>(false);
  const [search, setSearch] = useState<string>("");
  const toggleDeleteModal = (user: any) => setDeleteModal(user);
  const debouncedSearch = useDebounce(search, 500);
  const [filters, setFilters] = useState<any>([]);
  const { toast } = useToast();
  const refetchRef = useRef<any>(null);

  const handleActivate = (userId: string) => {
    hotToast.loading("Activating user...");
    api
      .updateUserActive(userId, true)
      .then(() => {
        hotToast.dismiss();
        if (refetchRef.current) {
          refetchRef.current();
        }
        toast({
          description: "User activated",
        });
      })
      .catch((error) => {
        hotToast.dismiss();

        console.log(error);
        toast({
          description:
            error?.response?.data?.message || `Failed to activate user`,
          variant: "destructive",
        });
      });
  };

  const columns: ColumnDef<User>[] = [
    {
      accessorKey: "fullName",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            //onClick={SortHeader(column)}
            className="p-0"
          >
            Name
            {/* {SortIcon(column)} */}
          </Button>
        );
      },
    },
    {
      accessorKey: "planType",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            // onClick={SortHeader(column)}
            className="p-0"
          >
            Plan
            {/* {SortIcon(column)} */}
          </Button>
        );
      },
      cell: ({ row }) => {
        return row.original.tier ? (
          <SubscriptionTitle tier={row.original.tier} />
        ) : (
          "-"
        );
      },
    },
    {
      accessorKey: "totalSpend",
      header: "Total spend",
      cell: ({ row }) => {
        return <span>{parseInt(row.original?.totalSpend)}</span>;
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        if (row.original.subscription) {
          const { isActive, cancelledAt, updatedAt } =
            row.original.subscription;

          let text = "-";
          let status = "";
          let statusDescription = "";

          if (isActive && !cancelledAt) {
            if (moment().isAfter(moment(updatedAt).add(1, "month"))) {
              // If current time is after the renewal date
              text = `Past due on ${moment(updatedAt).format(
                "YYYY-MM-DD HH:mm"
              )}`;
              status = "past_due";
              statusDescription = `${
                row.original.email
              } subscription is past due since ${moment(updatedAt).format(
                "YYYY-MM-DD HH:mm"
              )}`;
            } else {
              const renewDate = moment(updatedAt).add(1, "month").fromNow();
              // Otherwise, show the renews in text
              text = `Renews in ${
                renewDate === "in a month" ? "a month" : renewDate
              }`;
              status = "active";
              statusDescription = `${
                row.original.email
              } renews their subscription on ${moment(updatedAt)
                .add(1, "month")
                .format("YYYY-MM-DD HH:mm")}`;
            }
          } else if (!isActive && cancelledAt) {
            // If the subscription is not active and it is cancelled
            text = `Churned on ${moment(cancelledAt).format(
              "YYYY-MM-DD HH:mm"
            )}`;
            status = "churned";
            statusDescription = `${
              row.original.email
            } churned their subscription on ${moment(cancelledAt).format(
              "YYYY-MM-DD HH:mm"
            )}`;
          } else if (!isActive && moment().isAfter(moment(updatedAt))) {
            // If the subscription is not active and the date is past
            text = `Past due on ${moment(updatedAt).format(
              "YYYY-MM-DD HH:mm"
            )}`;
            status = "past_due";
            statusDescription = `${
              row.original.email
            } subscription is past due since ${moment(updatedAt).format(
              "YYYY-MM-DD HH:mm"
            )}`;
          }

          return (
            <Status
              status={[
                {
                  status: status,
                  statusText: text,
                  statusDescription: statusDescription,
                },
              ]}
            />
          );
        } else {
          return "-";
        }
      },
    },
    {
      accessorKey: "totalSellers",
      header: "Total sellers",
      cell: ({ row }) => {
        return (
          <span>
            {row.original.totalSellers}/
            {getTotalSellers(row?.original?.tier, subscriptionList)}
          </span>
        );
      },
    },
    {
      accessorKey: "links",
      header: "Links",
      cell: ({ row }) => {
        return (
          <Links
            links={[
              {
                email: row.original.email,
                stripeCustomerId: row.original.stripeCustomerId,
              },
            ]}
          />
        );
      },
    },
    {
      accessorKey: "joinedOn",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            //onClick={SortHeader(column)}
            className="p-0"
          >
            Joined on
            {/* {SortIcon(column)} */}
          </Button>
        );
      },
      cell: ({ row }) => {
        return (
          <span>{moment(row.original.joinedOn).format("MMM DD, YYYY")}</span>
        );
      },
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => {
        // console.log(row.original);
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="[&>div]:cursor-pointer">
              <DropdownMenuItem onClick={() => handleActivate(row.original.id)}>
                Activate user
              </DropdownMenuItem>
              <DropdownMenuItem
                className="text-red-700 font-[500] hover:!text-red-700"
                onClick={() => toggleDeleteModal(row.original)}
              >
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerContent className="bg-[#F8FAFC] !outline-none font-inter h-[100dvh]">
        <DeleteModal
          open={!!deleteModal}
          handleModal={(refresh?: boolean) => {
            if (refresh && refetchRef.current) {
              refetchRef.current();
            }
            setDeleteModal(false);
          }}
          user={deleteModal}
        />

        <div className="border-slate-300 w-full border-b-solid border-b-[1px] py-[0.875rem] px-[0.9375rem] h-[3.25rem] lg:flex hidden">
          <h1 className="text-[1.125rem] font-[500]">Deactivated users</h1>
        </div>
        <div className="flex flex-col p-[1.125rem] gap-5">
          <UserFilterView
            search={search}
            setSearch={setSearch}
            filters={filters}
            onClear={() => setFilters({})}
            onChange={(updates) => {
              setFilters({
                ...filters,
                ...updates,
              });
            }}
            showFilter={false}
          />
          <DataTable
            columns={columns}
            search={debouncedSearch}
            filters={filters}
            showActive={false}
            name="blocked-users"
            onRefetchLoad={(refetch) => (refetchRef.current = refetch)}
          />
        </div>

        <DrawerClose
          asChild
          onClick={onClose}
          className="top-4 right-4 absolute cursor-pointer"
        >
          <CloseIcon />
        </DrawerClose>
      </DrawerContent>
    </Drawer>
  );
}

function DeleteModal({
  open,
  handleModal,
  user,
}: {
  open: boolean;
  handleModal: (flag?: boolean) => void;
  user: any;
}) {
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  const handleDelete = () => {
    setLoading(true);

    api
      .deleteUser(user.id)
      .then(() => {
        setLoading(false);

        handleModal(true);
        toast({
          description: "User deleted successfully",
        });
      })
      .catch((error) => {
        setLoading(false);

        console.log(error);
        toast({
          description:
            error?.response?.data?.message || "Failed to delete user",
          variant: "destructive",
        });
      });
  };
  return (
    <Modal open={open} title="" noClose handleModal={handleModal}>
      <h2 className="font-grotesk text-black text-[1.25rem]">Delete user</h2>
      <p className="mt-3">
        Are you sure you want to permanently delete the user “{user?.fullName}”?{" "}
      </p>
      <div className="flex flex-row gap-3 mt-[1.125rem] justify-end">
        <Button
          disabled={loading}
          variant="outline"
          onClick={() => handleModal()}
        >
          Go back
        </Button>
        <Button
          disabled={loading}
          onClick={handleDelete}
          className="flex items-center gap-1"
          variant="delete"
        >
          <span>Yes, delete</span>

          {loading && <Spinner />}
        </Button>
      </div>
    </Modal>
  );
}
function DeactivateModal({
  open,
  handleModal,
}: {
  open: boolean;
  handleModal: () => void;
}) {
  return (
    <Modal open={open} title="" noClose handleModal={handleModal}>
      <h2 className="font-grotesk text-black text-[1.25rem]">
        Deactivate user
      </h2>
      <p className="mt-3">
        Are you sure you want to deactivate the user “someone”?{" "}
      </p>
      <div className="flex flex-row gap-3 mt-[1.125rem] justify-end">
        <Button variant="outline" onClick={handleModal}>
          Go back
        </Button>
        <Button variant="delete">Yes, deactivate</Button>
      </div>
    </Modal>
  );
}
