import { Button } from "../@/components/ui/button";
import LogoIcon from "../assets/icons/logo.svg";
import MinLogoIcon from "../assets/icons/min-logo.svg";
import { Link } from "react-router-dom";
import { ReactComponent as SidebarIcon } from "../assets/icons/sidebar-close.svg";
import { ReactComponent as SellersIcon } from "../assets/icons/sellers.svg";
import { ReactComponent as UsersIcon } from "../assets/icons/users.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/setting.svg";
import { ReactComponent as DashboardIcon } from "../assets/icons/dashboard.svg";
import { ReactComponent as MenuIcon } from "../assets/icons/menu.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/x.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout.svg";
import { useState } from "react";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "../@/components/ui/drawer";
import useViewportHeight from "../helpers/hooks/useViewportHeight";
import { cn } from "../@/lib/utils";
import { clearTokenAndUser } from "../helpers/utils";

export default function Sidebar() {
  const [collapsed, setCollapsed] = useState(false);
  const viewportHeight = useViewportHeight();

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const handleLogout = () => {
    // clear local storage
    // redirect to login
    window.location.href = "/login";
    localStorage.clear();
    clearTokenAndUser();
  };

  const path = window.location.pathname.split("/").filter((x) => x !== "");

  // Mobile/Tablet
  if (window.innerWidth < 1024) {
    return (
      <div className="lg:hidden flex">
        <Drawer direction="left">
          <div className="w-full [&>button]:w-full [&>button]:p-3 relative top-0 left-0 bg-[transparent] z-[9] border-b-solid border-b-[1px] border-slate-300">
            <DrawerTrigger>
              <div className="flex gap-[0.625rem] items-center w-full align-center">
                <MenuIcon className="w-[1.5rem] h-[1.5rem] text-slate-500" />
                <h1 className="text-[1.125rem] font-[500]">
                  {path[0] === "dashboard"
                    ? "Dashboard"
                    : path[0] === "bookmarks"
                    ? "Saved Products"
                    : path[0] === "sellers"
                    ? "Sellers"
                    : path[0] === "settings"
                    ? "Settings"
                    : path[0] === "subscription"
                    ? "Subscription"
                    : ""}
                </h1>
              </div>
            </DrawerTrigger>
          </div>

          <DrawerContent>
            <div
              className="flex flex-col h-screen relative"
              style={{ height: viewportHeight }}
            >
              <div className="p-2 border-slate-300 border-b-solid border-b-[1px] flex justify-between gap-4 items-center pl-4">
                <Link to="/dashboard">
                  <img src={LogoIcon} alt="logo" className="h-[1.375rem]" />
                </Link>
                <DrawerClose>
                  <Button className="p-[0.625rem]" variant="ghost">
                    <CloseIcon className="w-[1.5rem] h-[1.5rem] text-slate-500" />
                  </Button>
                </DrawerClose>
              </div>
              <div className="w-full p-2 grid gap-2">
                <DashboardLink />
                <UserLink />
                <SellerLink />
              </div>
              <button
                className="absolute bottom-0 w-full flex flex-row gap-2 px-3 py-4"
                onClick={handleLogout}
              >
                {/* <SettingsLink /> */}
                <LogoutIcon />
                <span className="font-medium text-sm leading-[120%] text-slate-900">
                  Logout
                </span>
              </button>
            </div>
          </DrawerContent>
        </Drawer>
      </div>
    );
  }

  // Desktop Collapsed
  if (collapsed) {
    return (
      <div>
        <div className="flex flex-col h-[100vh] border-r border-r-slate-300 relative">
          <div className="w-full p-2 !pb-[7px] border-slate-300 border-b-solid border-b-[1px] flex justify-center gap-4 items-center self-center">
            <Button
              variant="outline"
              onClick={toggleSidebar}
              className="w-9 h-9 !p-0"
            >
              <SidebarIcon className="text-slate-500 w-4 h-4 rotate-[180deg]" />
            </Button>
          </div>
          {/* <div className="p-2 border-slate-300 border-b-solid border-b-[1px] flex justify-between gap-4 items-center self-center">
            <Button
              variant="outline"
              onClick={toggleSidebar}
              className="p-[0.625rem] h-auto"
            >
              <SidebarIcon className="text-slate-500 rotate-[180deg]" />
            </Button>
          </div> */}
          <div className="w-full p-2 grid gap-2">
            <DashboardLink collapsed />
            <UserLink collapsed />
            <SellerLink collapsed />
          </div>
          <button
            className="absolute bottom-0 w-full flex flex-row gap-2 px-3 py-4 border-t border-slate-300 justify-center"
            onClick={handleLogout}
          >
            <LogoutIcon />
          </button>
        </div>
      </div>
    );
  }

  // Desktop Sidebar
  return (
    <div className="lg:flex hidden flex-col h-[100vh] border-r border-r-slate-300 relative w-[245px]">
      <div className="p-2 border-slate-300 border-b-solid border-b-[1px] flex justify-between gap-4 items-center pl-4 h-[3.25rem]">
        <Link to="/dashboard">
          <img src={LogoIcon} alt="logo" className="h-[1.375rem]" />
        </Link>

        <Button
          className="p-[0.625rem] lg:flex hidden h-auto"
          variant="outline"
          onClick={toggleSidebar}
        >
          <SidebarIcon className="text-slate-500" />
        </Button>
      </div>
      <div className="w-full p-2 grid gap-2">
        <DashboardLink />
        <UserLink />
        <SellerLink />
      </div>

      <button
        className="absolute bottom-0 w-full flex flex-row gap-2 px-3 py-4 border-t border-slate-300"
        onClick={handleLogout}
      >
        <LogoutIcon />
        <span className="font-medium text-sm leading-[120%] text-slate-900">
          Logout
        </span>
      </button>
    </div>
  );
}

function SellerLink({ collapsed }: { collapsed?: boolean }) {
  return (
    <CustomLink
      to="/sellers"
      activePath="/sellers"
      Icon={SellersIcon}
      label="Manage sellers"
      collapsed={collapsed}
    />
  );
}

function UserLink({ collapsed }: { collapsed?: boolean }) {
  return (
    <CustomLink
      to="/users"
      activePath="/users"
      Icon={UsersIcon}
      label="Manage users"
      collapsed={collapsed}
    />
  );
}

function DashboardLink({ collapsed }: { collapsed?: boolean }) {
  return (
    <CustomLink
      to="/dashboard"
      activePath="/dashboard"
      Icon={DashboardIcon}
      label="Dashboard"
      collapsed={collapsed}
    />
  );
}

function SettingsLink({ collapsed }: { collapsed?: boolean }) {
  return (
    <div className="w-full p-2 gap-2 grid border-slate-300 border-t-solid border-t-[1px]">
      <CustomLink
        to="/settings"
        activePath="/settings"
        Icon={SettingsIcon}
        label="Settings"
        collapsed={collapsed}
      />
    </div>
  );
}

function CustomLink({
  to,
  activePath,
  Icon,
  label,
  collapsed = false,
}: {
  to: string;
  activePath: string;
  Icon: any;
  label: string;
  collapsed?: boolean;
}) {
  const isActive = window.location.pathname === activePath;

  if (window.innerWidth < 1024) {
    return (
      <Link to={to}>
        <DrawerClose className="w-full">
          <Button
            className={cn(
              "flex gap-2 justify-start px-3 w-full",
              collapsed && "!justify-center"
            )}
            variant={isActive ? "default" : "ghost"}
          >
            <Icon
              className={cn({
                "[&>g>path]:stroke-white [&>path]:stroke-white": isActive,
              })}
            />
            {!collapsed && <span>{label}</span>}
          </Button>
        </DrawerClose>
      </Link>
    );
  }

  return (
    <Link to={to}>
      <Button
        className={cn(
          "flex gap-2 justify-start px-3 w-full",
          collapsed && "!justify-center"
        )}
        variant={isActive ? "default" : "ghost"}
      >
        <Icon
          className={cn({
            "[&>g>path]:stroke-white [&>path]:stroke-white": isActive,
          })}
        />
        {!collapsed && <span>{label}</span>}
      </Button>
    </Link>
  );
}
