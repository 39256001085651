import { useEffect, useState } from "react";
import { getUser } from "../../../../helpers/utils";
import { useToast } from "../../../../@/components/ui/use-toast";
import { api } from "../../../../helpers/api";
import Modal from "../../../../components/Modal";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../@/components/ui/select";
import { countries } from "../../../../helpers/consts";
import CustomButton from "../../../../components/Button";

export function ChangeMarketPlace({
  isOpen,
  onClose,
  refetch,
  userId,
  userCountry,
}: {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  userId: string;
  userCountry: string;
}) {
  const [form, setForm] = useState({
    country: "",
    // experience: user?.experience,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const handleSubmit = () => {
    setIsLoading(true);

    api
      .putUserProfile(userId, form)
      .then(async (res) => {
        toast({
          description: "Marketplace updated successfully",
        });
        setIsLoading(false);
        onClose();
        refetch();
        // parse user in local storage to update the experience and country
        const user = getUser();
        user.country = form.country;
        localStorage.setItem("user", JSON.stringify(user));
      })
      .catch(() => {
        toast({
          description: "Account setup failed",
          variant: "destructive",
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen && userCountry) {
      setForm({
        country: userCountry,
        // experience: user?.experience,
      });
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      title="AMAZON MARKETPLACE"
      handleModal={onClose}
      contentClassName="z-[200]"
    >
      <div className="grid gap-3 mt-5">
        {/* <h2 className="font-grotesk text-[0.875rem] font-[500] text-slate-900">
          AMAZON MARKETPLACE
        </h2> */}
        <p>
          Please specify the Amazon marketplace where you conduct your sales and
          your experience!
        </p>
        <div className="grid gap-4">
          <label className="grid gap-2">
            Country
            <Select
              onValueChange={(value) => {
                setForm({
                  ...form,
                  country: value,
                });
              }}
              value={form.country}
            >
              <SelectTrigger className="w-full text-black">
                <SelectValue placeholder="Select an option" />
              </SelectTrigger>
              <SelectContent className="z-[202]">
                <SelectGroup>
                  {/* <SelectLabel>Fruits</SelectLabel> */}
                  {countries.map((country) => (
                    <SelectItem key={country.code} value={country.code}>
                      {country.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </label>
          <CustomButton
            onClick={handleSubmit}
            disabled={!form.country}
            loading={isLoading}
          >
            Done
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
}
