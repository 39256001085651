import React, { useCallback, useEffect, useState } from "react";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "../../@/components/ui/drawer";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as DeviceIcon } from "../../assets/icons/devices.svg";
import { ReactComponent as BookmarkIcon } from "../../assets/icons/bookmark.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/arrow-up-right.svg";
import { ReactComponent as CalendarPlusIcon } from "../../assets/icons/calendar-plus.svg";
import { ReactComponent as AtTheRateIcon } from "../../assets/icons/at-the-rate.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as UserMinusIcon } from "../../assets/icons/user-minus.svg";
import { ReactComponent as BlockIcon } from "../../assets/icons/block.svg";
import { ReactComponent as PencilIcon } from "../../assets/icons/pencil.svg";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../@/components/ui/hover-card";
import { ColorBoxIcon } from "../product-feed/components/ColorBoxIcon";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import moment from "moment";
import { Button } from "../../@/components/ui/button";
import Sellers from "./components/Sellers";
import Modal from "../../components/Modal";
import { useToast } from "../../@/components/ui/use-toast";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../@/components/ui/dropdown-menu";
import { ChangeMarketPlace } from "./components/components/ChangeMarketPlace";
import { countries } from "../../helpers/consts";
import { Form, Formik, useFormikContext } from "formik";
import FormField from "../../components/FormField";
import * as Yup from "yup";
import { USER_PORTAL_URL } from "../../env";
import Spinner from "../../components/Spinners";
import hotToast from "react-hot-toast";

function SubLabel({ children }: { children: React.ReactNode }) {
  return (
    <span className="font-semibold text-base text-slate-900 leading-[24px] flex flex-row items-center gap-1.5">
      {children}
    </span>
  );
}

function ValueWrapper({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={`text-slate-500 text-xs font-medium leading-[18px] border border-slate-200 bg-white rounded-[6px] py-[2px] w-fit px-1.5 flex gap-1 items-center ${className}`}
    >
      {children}
    </div>
  );
}

export function UserDetails({
  open,
  closeDrawer,
  selectedRow,
  toggleDeactivateModal,
}: {
  open: boolean;
  closeDrawer: () => void;
  selectedRow: any;
  toggleDeactivateModal: () => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [variant, setVariant] = useState<
    "delete" | "deactivate" | "cancel" | "marketplace" | "edit"
  >("delete");
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [tagList, setTagList] = useState<string[]>([]);
  const { toast } = useToast();

  const fetchTags = useCallback(async (userId?: string) => {
    if (!userId) return;
    try {
      const res = await api.getTags(userId);
      setTagList(res.tags || []);
    } catch (e) {}
  }, []);

  const { data, isLoading, refetch } = useQuery(
    ["user-details", selectedRow?.id],
    () => api.fetchUser(selectedRow?.id),
    {
      enabled: open && !!selectedRow?.id,
    }
  );
  const { data: subscriptionData, isLoading: subscriptionLoading } = useQuery(
    ["user-subscription", selectedRow?.id],
    () => api.getUserSubscription(selectedRow?.id),
    {
      enabled: open && !!selectedRow?.id,
    }
  );

  const handleCustomerLink = (customerId?: string) => {
    if (customerId) {
      window.open(
        `https://dashboard.stripe.com/customers/${customerId}`,
        "_blank"
      );
    }
  };
  const handleSubscriptionLink = (subscriptionId?: string) => {
    // https://dashboard.stripe.com/subscriptions/sub_1PXpAmKcumHzyx15RTNIKlSG
    if (subscriptionId) {
      window.open(
        `https://dashboard.stripe.com/subscriptions/${subscriptionId}`,
        "_blank"
      );
    }
  };

  const handleActivate = (userId: string) => {
    hotToast.loading("Activating user...");
    api
      .updateUserActive(userId, true)
      .then(() => {
        hotToast.dismiss();
        toast({
          description: "User activated",
        });
      })
      .catch((error) => {
        hotToast.dismiss();

        console.log(error);
        toast({
          description:
            error?.response?.data?.message || `Failed to activate user`,
          variant: "destructive",
        });
      });
  };

  console.log(selectedRow);
  useEffect(() => {
    if (open && selectedRow?.id) {
      fetchTags(selectedRow?.id);
    }
  }, [open, selectedRow?.id]);
  console.log("subscriptionData", subscriptionData, data);
  const userStatus = data?.subscription?.cancelledAt ? "canceled" : "active";
  return (
    <Drawer open={open} onClose={closeDrawer}>
      <DrawerContent className="bg-[#F8FAFC] !outline-none font-inter h-[100dvh]">
        <div className="relative h-[100dvh]">
          <div className="flex items-stretch gap-[19px] overflow-auto h-[calc(100dvh-60px)] px-6 mt-12">
            <div className="w-[350px] grid gap-5 col-span-1 content-start">
              <div className="grid gap-3 content-start">
                <span className="font-semibold text-sm text-slate-500 h-fit">
                  OVERVIEW
                </span>
                <div className="bg-white border border-slate-200 p-4 rounded-[6px] flex flex-col gap-2">
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-col gap-2">
                      <SubLabel>Total Spend</SubLabel>
                      {isLoading ? (
                        <div className="h-3 w-12 animate-pulse bg-slate-100 rounded-md"></div>
                      ) : (
                        <span className="text-slate-500 text-sm font-medium">
                          ${data?.totalPayments || 0}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      <SubLabel>Joined</SubLabel>
                      {isLoading ? (
                        <div className="h-3 w-12 animate-pulse bg-slate-100 rounded-md"></div>
                      ) : (
                        <HoverCard>
                          <HoverCardTrigger className="h-5 grid">
                            <span className="text-slate-500 text-sm font-medium hover:underline cursor-pointer h-fit">
                              {moment(data?.createdAt).fromNow()}
                            </span>
                          </HoverCardTrigger>
                          <HoverCardContent side="top" className="text-xs">
                            {moment(data?.createdAt).format(
                              "MMMM D, YYYY h:mm A [PST]"
                            )}
                          </HoverCardContent>
                        </HoverCard>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      <SubLabel>MRR</SubLabel>
                      {isLoading ? (
                        <div className="h-3 w-12 animate-pulse bg-slate-100 rounded-md"></div>
                      ) : (
                        <span className="text-slate-500 text-sm font-medium">
                          ${data?.totalPayments || 0}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="">
                    <div className="flex flex-col gap-2">
                      <SubLabel>
                        <span>User role</span>
                        <EditRoleDropdown
                          userId={selectedRow?.id}
                          refetch={refetch}
                        />
                      </SubLabel>
                      {isLoading ? (
                        <div className="h-3 w-12 animate-pulse bg-slate-100 rounded-md"></div>
                      ) : (
                        <div className="text-slate-500 text-xs font-medium leading-[18px] border border-200 border-slate-200 rounded-[6px] bg-slate-50 px-1.5 py-0.5 w-fit font-inter">
                          {data?.type === "U" ? "User" : "Admin"}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="">
                    <div className="flex flex-col gap-2">
                      <SubLabel>
                        <span>Marketplace</span>
                        <button
                          className="text-slate-900 text-xs font-medium leading-[18px] border border-200 border-slate-200 rounded-[6px] px-1.5 py-0.5 w-fit font-inter"
                          onClick={() => {
                            setVariant("marketplace");
                            toggleModal();
                          }}
                        >
                          edit
                        </button>
                      </SubLabel>
                      {isLoading ? (
                        <div className="h-3 w-12 animate-pulse bg-slate-100 rounded-md"></div>
                      ) : (
                        <div className="text-slate-500 text-xs font-medium leading-[18px] border border-200 border-slate-200 rounded-[6px] bg-slate-50 px-1.5 py-0.5 w-fit font-inter">
                          {countries.find(
                            (country) => country.code === data?.country
                          )?.name || "Select a country"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {subscriptionData?.length > 0 && (
                <div className="grid gap-3 content-start">
                  <span className="font-semibold text-sm text-slate-500 h-fit">
                    STRIPE INFO
                  </span>
                  <div className="bg-white border border-slate-200 p-4 rounded-[6px] flex flex-col gap-1.5">
                    {subscriptionData?.[0]?.customerId && (
                      <div className="flex gap-2 flex-col">
                        <SubLabel>Customer ID</SubLabel>
                        {isLoading ? (
                          <div className="h-3 w-12 animate-pulse bg-slate-100 rounded-md"></div>
                        ) : (
                          <ValueWrapper className="">
                            <button
                              className="flex items-center gap-1"
                              onClick={() =>
                                handleCustomerLink(
                                  subscriptionData?.[0]?.customerId
                                )
                              }
                            >
                              <span className="underline">
                                {subscriptionData?.[0]?.customerId}
                              </span>
                              <LinkIcon />
                            </button>
                          </ValueWrapper>
                        )}
                      </div>
                    )}
                    {subscriptionData?.[0]?.subscriptionId && (
                      <div className="flex gap-2 flex-col">
                        <SubLabel>Subscription ID</SubLabel>
                        {isLoading ? (
                          <div className="h-3 w-12 animate-pulse bg-slate-100 rounded-md"></div>
                        ) : (
                          <ValueWrapper className="">
                            <button
                              className="flex items-center gap-1"
                              onClick={() =>
                                handleSubscriptionLink(
                                  subscriptionData?.[0]?.subscriptionId
                                )
                              }
                            >
                              <span className="underline">
                                {subscriptionData?.[0]?.subscriptionId}
                              </span>
                              <LinkIcon />
                            </button>
                          </ValueWrapper>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="grid gap-3 content-start mb-6">
                <span className="font-semibold text-sm text-slate-500 h-fit">
                  USER ACTIVITY
                </span>
                <div className="bg-white border border-slate-200 p-4 rounded-[6px] flex flex-col gap-1.5">
                  <div className="flex gap-2 flex-col">
                    <SubLabel>Last signed in at</SubLabel>
                    {isLoading ? (
                      <div className="h-3 w-12 animate-pulse bg-slate-100 rounded-md"></div>
                    ) : (
                      <ValueWrapper className="">
                        {data?.lastActive
                          ? moment(data?.lastActive).format(
                              "MMMM D, YYYY h:mm A [PST]"
                            )
                          : "-"}
                      </ValueWrapper>
                    )}
                  </div>
                  <div className="flex gap-2 flex-col">
                    <SubLabel>Password last changed at</SubLabel>
                    {isLoading ? (
                      <div className="h-3 w-12 animate-pulse bg-slate-100 rounded-md"></div>
                    ) : (
                      <ValueWrapper className="">
                        {data?.lastPasswordChanged
                          ? moment(data?.lastPasswordChanged).format(
                              "MMMM D, YYYY h:mm A [PST]"
                            )
                          : "-"}
                      </ValueWrapper>
                    )}
                  </div>
                  <div className="flex gap-2 flex-col">
                    <SubLabel>Last email notification at</SubLabel>
                    <ValueWrapper className="">
                      {data?.lastEmailNotification
                        ? moment(data?.lastEmailNotification).format(
                            "MMMM D, YYYY h:mm A [PST]"
                          )
                        : "-"}
                    </ValueWrapper>
                  </div>
                  <div className="flex gap-2 flex-col">
                    <SubLabel>Devices signed in </SubLabel>
                    {isLoading ? (
                      <div className="h-3 w-12 animate-pulse bg-slate-100 rounded-md"></div>
                    ) : (
                      <ValueWrapper className="">
                        <DeviceIcon />
                        <span>{data?.totalDevices || 0}</span>
                      </ValueWrapper>
                    )}
                  </div>
                  <div className="flex gap-2 flex-col">
                    <SubLabel>Total saved products</SubLabel>
                    {isLoading ? (
                      <div className="h-3 w-12 animate-pulse bg-slate-100 rounded-md"></div>
                    ) : (
                      <ValueWrapper className="">
                        <BookmarkIcon />
                        <span>{data?.totalSavedProducts || 0}</span>
                      </ValueWrapper>
                    )}
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex gap-2 flex-col">
                      <SubLabel>Active</SubLabel>
                      {isLoading ? (
                        <div className="h-3 w-12 animate-pulse bg-slate-100 rounded-md"></div>
                      ) : (
                        <ValueWrapper className="">
                          <ColorBoxIcon
                            border={"border-[#14532D]"}
                            bg="bg-lime-50"
                          />
                          <span>{data?.activeSellersCount}</span>
                        </ValueWrapper>
                      )}
                    </div>

                    <div className="flex gap-2 flex-col">
                      <SubLabel>Paused</SubLabel>
                      {isLoading ? (
                        <div className="h-3 w-12 animate-pulse bg-slate-100 rounded-md"></div>
                      ) : (
                        <ValueWrapper className="">
                          <ColorBoxIcon
                            border={"border-red-900"}
                            bg="bg-red-50"
                          />

                          <span>{data?.pausedSellersCount}</span>
                        </ValueWrapper>
                      )}
                    </div>

                    <div className="flex gap-2 flex-col">
                      <SubLabel>Favorited</SubLabel>
                      {isLoading ? (
                        <div className="h-3 w-12 animate-pulse bg-slate-100 rounded-md"></div>
                      ) : (
                        <ValueWrapper className="">
                          <ColorBoxIcon
                            border={"border-[#FFC42E]"}
                            bg="bg-amber-50"
                          />

                          <span>{data?.favoriteSellersCount}</span>
                        </ValueWrapper>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="bg-white border border-slate-200 p-4 rounded-[6px] w-full mt-8 flex justify-between">
                <div className="flex flex-col gap-3">
                  <div className="flex items-center gap-2">
                    <span className="text-lg font-medium text-slate-800 font-grotesk">
                      {selectedRow?.fullName}
                    </span>
                    <div className="flex items-center gap-1 rounded-[6px] border border-slate-200 bg-slate-50 px-1.5 py-[2px]">
                      <ColorBoxIcon
                        bg={
                          userStatus === "active" ? "bg-lime-50" : "bg-red-50"
                        }
                        border={
                          userStatus === "active"
                            ? "border-[#14532D]"
                            : "border-red-900"
                        }
                      />
                      <span className="text-xs capitalize font-medium leading-[18px] text-slate-900">
                        {userStatus}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-1 rounded-[6px] w-fit border border-slate-200 bg-slate-50 px-1.5 py-[2px]">
                    <AtTheRateIcon />
                    <span className="text-xs font-medium leading-[18px] text-slate-500">
                      {selectedRow?.email}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="flex items-center gap-2">
                    <Button
                      className="!h-7 text-xs font-medium flex items-center gap-1"
                      variant="outline"
                      onClick={() => {
                        setVariant("deactivate");
                        toggleModal();
                      }}
                    >
                      <BlockIcon className="w-4 h-4" />
                      {data?.isActive
                        ? "Deactivate account"
                        : "Activate account"}
                    </Button>
                    <Button
                      className="!h-7 text-xs font-medium flex items-center gap-1"
                      variant="outline"
                      onClick={() => {
                        setVariant("cancel");
                        toggleModal();
                      }}
                      disabled={
                        subscriptionData?.length === 0 || subscriptionLoading
                      }
                    >
                      <UserMinusIcon className="w-4 h-4" />
                      Cancel
                    </Button>
                    <Button
                      className="!h-7 text-xs font-medium flex items-center gap-1"
                      variant="outline"
                      onClick={() => {
                        setVariant("delete");
                        toggleModal();
                      }}
                    >
                      <TrashIcon className="w-4 h-4" />
                      Delete
                    </Button>
                    <Button
                      className="!h-7 text-xs font-medium flex items-center gap-1"
                      onClick={() => {
                        toggleModal();
                        setVariant("edit");
                      }}
                    >
                      <PencilIcon className="w-4 h-4" />
                      Edit
                    </Button>
                  </div>
                  <div className="flex w-fit self-end items-center gap-1 rounded-[6px] border border-slate-200 bg-slate-50 px-1.5 py-[2px]">
                    <CalendarPlusIcon />
                    <span className="text-xs font-medium leading-[18px] text-slate-500">
                      Joined on{" "}
                      {moment(selectedRow?.joinedOn).format("MMM DD, YYYY")}
                    </span>
                  </div>
                </div>
              </div>
              {selectedRow?.id && (
                <div>
                  <Sellers
                    user={selectedRow}
                    activeSellersCount={data?.activeSellersCount}
                    tier={data?.tier}
                    tagList={tagList}
                  />
                </div>
              )}
            </div>
          </div>
          <Modals
            open={isModalOpen}
            handleModal={(flag?: boolean) => {
              if (flag) {
                refetch();
              }
              toggleModal();
            }}
            variant={variant}
            userId={selectedRow?.id}
            fullName={selectedRow?.fullName}
            subscriptionId={
              !subscriptionLoading && subscriptionData?.[0]?.subscriptionId
            }
            refetch={refetch}
            userCountry={data?.country}
            isActive={data?.isActive}
            email={data?.email}
            onUserDetailsClose={() => closeDrawer()}
          />
          <DrawerClose
            asChild
            onClick={closeDrawer}
            className="top-6 right-6 absolute cursor-pointer"
          >
            <CloseIcon />
          </DrawerClose>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

function Modals({
  open,
  handleModal,
  variant = "delete",
  userId,
  fullName,
  subscriptionId,
  refetch,
  userCountry,
  isActive,
  email,
  onUserDetailsClose,
}: {
  open: boolean;
  handleModal: (flag?: boolean) => void;
  variant?: "delete" | "deactivate" | "cancel" | "marketplace" | "edit";
  userId: string;
  fullName: string;
  subscriptionId: string;
  refetch: () => void;
  userCountry: string;
  isActive: boolean;
  email: string;
  onUserDetailsClose: () => void;
}) {
  switch (variant) {
    case "delete":
      return (
        <DeleteUserModal
          open={open}
          handleModal={(flag?: boolean) => {
            if (flag) {
              onUserDetailsClose();
            }
            handleModal(flag);
          }}
          userId={userId}
          fullName={fullName}
        />
      );
    case "deactivate":
      return (
        <DeactivateUserModal
          open={open}
          handleModal={(flag?: boolean) => {
            if (flag) {
              onUserDetailsClose();
            }
            handleModal(flag);
          }}
          userId={userId}
          fullName={fullName}
          isActive={isActive}
        />
      );
    case "cancel":
      return (
        <CancelSubscriptionModal
          open={open}
          handleModal={handleModal}
          userId={userId}
          fullName={fullName}
          subscriptionId={subscriptionId}
          refetch={refetch}
        />
      );
    case "marketplace":
      return (
        <ChangeMarketPlace
          isOpen={open}
          onClose={handleModal}
          refetch={refetch}
          userId={userId}
          userCountry={userCountry}
        />
      );
    case "edit":
      return (
        <EditUserModal
          isOpen={open}
          handleModal={handleModal}
          refetch={refetch}
          fullName={fullName}
          email={email}
          userId={userId}
        />
      );
    default:
      return null;
  }
}
function DeleteUserModal({
  open,
  handleModal,
  userId,
  fullName,
}: {
  open: boolean;
  handleModal: (flag?: boolean) => void;
  userId: string;
  fullName: string;
}) {
  const [loading, setLoading] = useState(false);

  const { toast } = useToast();
  const handleDelete = () => {
    setLoading(true);

    api
      .deleteUser(userId)
      .then(() => {
        setLoading(false);

        handleModal(true);
        toast({
          description: "User deleted successfully",
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast({
          description:
            error?.response?.data?.message || "Failed to delete user",
          variant: "destructive",
        });
      });
  };

  return (
    <Modal open={open} title="" noClose handleModal={handleModal}>
      <h2 className="font-grotesk text-black text-[1.25rem]">Delete user</h2>
      <p className="mt-3">
        Are you sure you want to permanently delete the user “{fullName}”?{" "}
      </p>
      <div className="flex flex-row gap-3 mt-[1.125rem] justify-end">
        <Button
          disabled={loading}
          variant="outline"
          onClick={() => handleModal()}
        >
          Go back
        </Button>
        <Button
          disabled={loading}
          onClick={handleDelete}
          className="flex items-center gap-1"
          variant="delete"
        >
          <span>Yes, delete</span>

          {loading && <Spinner />}
        </Button>
      </div>
    </Modal>
  );
}
function DeactivateUserModal({
  open,
  handleModal,
  userId,
  fullName,
  isActive,
}: {
  open: boolean;
  handleModal: (flag?: boolean) => void;
  userId: string;
  fullName: string;
  isActive: boolean;
}) {
  const [loading, setLoading] = useState(false);

  const { toast } = useToast();
  const handleDeactivate = () => {
    setLoading(true);

    api
      .updateUserActive(userId, !isActive)
      .then(() => {
        setLoading(false);

        handleModal(true);
        toast({
          description: isActive ? "User deactivated" : "User activated",
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast({
          description:
            error?.response?.data?.message ||
            `Failed to ${isActive ? "deactivate" : "activate"} user`,
          variant: "destructive",
        });
      });
  };

  return (
    <Modal open={open} title="" noClose handleModal={handleModal}>
      <h2 className="font-grotesk text-black text-[1.25rem]">
        {isActive ? "Deactivate user" : "Activate user"} user
      </h2>
      <p className="mt-3">
        Are you sure you want to {isActive ? "deactivate" : "activate"} the user
        “{fullName}”?{" "}
      </p>
      <div className="flex flex-row gap-3 mt-[1.125rem] justify-end">
        <Button
          disabled={loading}
          variant="outline"
          onClick={() => handleModal()}
        >
          Go back
        </Button>
        <Button
          variant="delete"
          disabled={loading}
          onClick={handleDeactivate}
          className="flex items-center gap-1"
        >
          <span>Yes, {isActive ? "deactivate" : "activate"}</span>
          {loading && <Spinner />}
        </Button>
      </div>
    </Modal>
  );
}
function CancelSubscriptionModal({
  open,
  handleModal,
  userId,
  fullName,
  subscriptionId,
  refetch,
}: {
  open: boolean;
  handleModal: () => void;
  userId: string;
  fullName: string;
  subscriptionId: string;
  refetch: () => void;
}) {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const handleCancelSubscription = () => {
    setLoading(true);

    api
      .cancelSubscription(userId, subscriptionId)
      .then(() => {
        handleModal();
        toast({
          description: "User subscription cancelled successfully",
        });
        setLoading(false);
        refetch();
        queryClient.invalidateQueries({
          queryKey: ["users"],
        });
        queryClient.invalidateQueries({
          queryKey: ["user-details"],
        });
        queryClient.invalidateQueries({
          queryKey: ["user-subscription"],
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          description:
            error?.response?.data?.message || "Failed to delete user",
          variant: "destructive",
        });
        setLoading(false);
      });
  };

  return (
    <Modal open={open} title="" noClose handleModal={handleModal}>
      <h2 className="font-grotesk text-black text-[1.25rem]">
        Cancel subscription
      </h2>
      <p className="mt-3">
        Are you sure you want to cancel the subscription for the user “
        {fullName}”?
      </p>
      <div className="flex flex-row gap-3 mt-[1.125rem] justify-end">
        <Button variant="outline" onClick={handleModal}>
          Go back
        </Button>
        <Button
          variant="delete"
          onClick={handleCancelSubscription}
          disabled={loading}
          className="flex flex-row gap-2"
        >
          Yes, cancel
          {loading && <Spinner />}
        </Button>
      </div>
    </Modal>
  );
}
function EditRoleDropdown({
  userId,
  refetch,
}: {
  userId: string;
  refetch: () => void;
}) {
  const { toast } = useToast();
  const handleEditRole = (userId: string, userType: "U" | "A") => () => {
    api
      .updateUser(userId, { userType })
      .then(() => {
        toast({
          description: "User role updated successfully",
        });
        refetch();
      })
      .catch((error) => {
        console.log(error);
        toast({
          description:
            error?.response?.data?.message || "Failed to update user role",
          variant: "destructive",
        });
      });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="text-slate-900 text-xs font-medium leading-[18px] border border-200 border-slate-200 rounded-[6px] px-1.5 py-0.5 w-fit font-inter">
          edit
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="center" className="[&>div]:cursor-pointer">
        <DropdownMenuItem onClick={handleEditRole(userId, "U")}>
          User
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleEditRole(userId, "A")}>
          Admin
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
function EditUserModal({
  isOpen,
  handleModal,
  refetch,
  fullName,
  email,
  userId,
}: {
  isOpen: boolean;
  handleModal: () => void;
  refetch: () => void;
  fullName: string;
  email: string;
  userId: string;
}) {
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  return (
    <Modal open={isOpen} handleModal={handleModal} title="Edit User">
      <Formik
        initialValues={{ fullName: "", email: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          // Handle form submission
          // api
          //   .inviteUser(values)
          //   .then(() => {
          //     handleModal();
          //     actions.setSubmitting(false);
          //     toast({
          //       description: "User invited successfully",
          //     });
          //   })
          //   .catch((err) => {
          //     actions.setSubmitting(false);
          //     toast({
          //       description: "Failed to invite user",
          //       variant: "destructive",
          //     });
          //   });
        }}
      >
        <EditUserFormContent
          fullName={fullName}
          email={email}
          userId={userId}
        />
      </Formik>
    </Modal>
  );
}
function EditUserFormContent({
  fullName,
  email,
  userId,
}: {
  fullName: string;
  email: string;
  userId: string;
}) {
  const { isValid, isSubmitting, values, resetForm } = useFormikContext<any>();
  const { toast } = useToast();

  useEffect(() => {
    resetForm({ values: { fullName, email } });
  }, [fullName, email]);

  return (
    <Form className="grid gap-[1.75rem] mt-4">
      <div className="grid gap-4">
        <FormField
          label="Full Name"
          name="fullName"
          type="text"
          placeholder="Enter your full name"
          defaultValue={values.fullName}
        />
        <FormField
          label="Email"
          name="email"
          type="email"
          placeholder="Enter your email"
          value={values.email}
        />
      </div>
      <div className="grid gap-3">
        <Button
          className="w-full"
          type="submit"
          disabled={!isValid || isSubmitting}
        >
          Edit
        </Button>
        <Button
          className="w-full"
          type="button"
          disabled={values.email.length === 0}
          variant="outline"
          onClick={() => {
            api
              .forgotPassword({
                resetPasswordUrl: USER_PORTAL_URL,
                email: values.email,
              })
              .then(() => {
                toast({
                  description: "Reset password link sent.",
                });
              })
              .catch((err) => {
                toast({
                  description:
                    err.response.data.message ||
                    "There was an error sending password link.",
                  variant: "destructive",
                });
              });
          }}
        >
          Send Reset Password Link
        </Button>
      </div>
    </Form>
  );
}
